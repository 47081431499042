import React from "react";

import './Card.scss';


const Card = (props) => {
    const {classes} = props
    
    return (
        <div className={`card_list ${classes ? classes : ''}`} >
            <div className={`${classes ? classes : ''} card_list--content`}>
                { props.children }
            </div>
        </div>
    )
}

export default Card