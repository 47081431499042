import React from "react";


import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,  
  TwitterShareButton
} from "react-share";

import {
  EmailIcon,
  FacebookIcon,  
  LinkedinIcon,  
  TwitterIcon,  
} from "react-share";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

const copyButtonStyle = {
  backgroundColor: 'transparent',
    border: 'none',
    padding: '0px',
    font: 'inherit',
    color: 'inherit',
    cursor: 'pointer',  
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#127df8',
    height: '3.5rem',
    width: '3.5rem',
    borderRadius: '50%'
  }
  
  const copyIconStyle = {        
    color: '#fff',
    fill: '#fff'    
}


const SharePortfolio = (props) => {
  const {portfolio} = props;
  const url = `${window.location.origin}/myportfolio/${portfolio.id}` 

  return (
  <div className="share-icons" style={{display: 'flex', alignItems:'flex-start', margin:'1rem 0'}}>
    <FacebookShareButton url={url}>
        <FacebookIcon round size='35'/>
    </FacebookShareButton>    

    <LinkedinShareButton 
      url={url} title={`${portfolio.name} | ${portfolio.title}`} description={portfolio.title} >
      <LinkedinIcon round size='35'/>
    </LinkedinShareButton>

    <TwitterShareButton url={url} title={`${portfolio.name} | ${portfolio.title}`}>
      <TwitterIcon round size='35'/>
    </TwitterShareButton>

    <EmailShareButton url={url} subject={`${portfolio.name} | ${portfolio.title}`}>
      <EmailIcon round size='35'/>
    </EmailShareButton>

    <button style={copyButtonStyle}>
      <FontAwesomeIcon icon={faCopy} onClick={() => {navigator.clipboard.writeText(url)}} style={copyIconStyle}/>
    </button>
    
    
  </div>
  )
}


export default SharePortfolio
