import api from "context/ApiInstance";

class UserData {
  getAll () {   
    return api.get('/api/v1/userData')    
  }
  viewedInstructions(){
    return api.post('/api/v1/userData/instructions', {Instructions: !this})
  }
  deleteUser(id) {
    return api.delete(`/api/v1/userData`)
  }
}


export default new UserData();

