import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";

import PortfolioProvider from 'context/PortfolioProvider'
import projectApi from 'api/projectsApi'
import AddProject from './Project'
import Card from "components/Card/Card";
import Modal from "components/Modal/Modal";
import ConfirmDelete from "components/ConfirmDelete/ConfirmDelete";

import {ReactComponent as TitleIcon} from 'icons/fa-diagram-project.svg';




const Projects = () => {
	const {projects, getMyProjects} = useContext(PortfolioProvider)
	const [addProject, setAddProject] = useState(false)
	const [editing, setEditing] = useState(false)
	const [projectToEdit, setProjectToEdit] = useState(null)

	const handleSetAddProject = () => {
		if (!editing) setAddProject(!addProject)
		if (editing) setEditing(!editing)
	}

	const handleEditProject = (projectData) => {
		setProjectToEdit(projectData)
		setEditing(!editing)
	}

	const handleDeleteProject = (id) => {		
		projectApi.delteMyProject(id).then(getMyProjects())		
	}


	return (
		<div className={`module ${editing || addProject ? 'editing' : ''}`}>
		<div className="module--title">
			<TitleIcon className="titleIcon"/>
			<h4>My Projects</h4>
		</div>

			{projects && projects.map((project, index) => {
				return <Card id={project.id} key={project.id} classes={'project_card'} >											
							<div className="img" style={{maxWidth: '100%', width: '100px'}}>							
								<img src={project.projectThumbnailUrl} alt={project.projectName} />
							</div>
							<div className="information">
								<Link to={project.projectLink} target="_blank" rel="noreferrer">{project.projectName}</Link> <br />
								<span><i>{project.projectCategory}</i></span>							
								
								<ul className="skill">{project.skillsUsed.map((skill, index2) => {
									return <li key={`skill_${index2}`}>{skill}</li>
								})}</ul>
							</div>
							<div className="btnGroup btnGroup-flex">
								<button className="btn warning" onClick={()=>{handleEditProject(project)}}>Edit</button>
								<ConfirmDelete item={project} delFunction={handleDeleteProject} />
							</div>						
					</Card>	
				
				
			})
			}
			
			{!addProject && <button className="btn btn-sticky--bottom" onClick={() => {setAddProject(true)}}>
				+ New Project
			</button>}
			
			{addProject && 
				<Modal><AddProject setAddProject={handleSetAddProject} getMyProjects={getMyProjects}/></Modal>}
			{editing    &&  <Modal><AddProject setAddProject={handleSetAddProject} getMyProjects={getMyProjects} project={projectToEdit} editing={editing}/></Modal>}
			
		</div>
	)
}

export default Projects

