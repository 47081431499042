import React, { createContext, useContext, useState } from "react";

import portfolioApi from "../api/portfolioApi";
import projectApi from 'api/projectsApi'
import employmentApi from "api/employmentApi";
import educationApi from "api/educationApi";
import skillApi from "api/skillApi";



const PortfolioContext = createContext();
export const PortfolioProvider = ({ children }) => {
  const [projects, setProjects] = useState(false);
  const [education, setEducation] = useState(false);
  const [portfolio, setPortfolio] = useState(false);
  const [employment, setEmployment] = useState(false);
  const [skills, setSkills] = useState(false)

  const getUsersPortfolios = async () => {
    const response = await portfolioApi.getUsersPortfolios()       
    if (response.status === 'success') {
      setPortfolio(response.data)           
    }       
  }
  
  const getMyProjects = async () => {
    await projectApi.getMyProjects().then((res) => {            
      if (res.status === 'success') {        
				if (res.userProjects !== projects) {
					setProjects(res.userProjects)
				}
			}
		})
    return false
	}

  const getUserEducation = async () => {
    await educationApi.getUserEducation().then((res) => {      
      setEducation(res.data)
    })
  }

  const getUserEmployment = async () => {
    await employmentApi.getUserEmployement().then((res) => {
      setEmployment(res.data)
    })
  }

  const getUserSkills = async () => {
    await skillApi.getMySkills().then((res) => {
      setSkills(res.skill)
    })
  }


  return (
    <PortfolioContext.Provider value={{ 
      getUsersPortfolios,
      portfolio, setPortfolio,
      getUserEducation,
      education, setEducation,      
      getMyProjects,               
      projects, setProjects,
      getUserEmployment,
      employment, setEmployment,
      getUserSkills,
      skills, setSkills
    }} >
      {children}
    </PortfolioContext.Provider>
  )
}

export const useGlobalContext = () => {
  return useContext(PortfolioContext)
}

export default PortfolioContext;