import React, {useState, useContext, useEffect} from "react";

import AppContext from "context/Appcontext";
import InputPassword from "components/Forms/InputPassword";
import authApi from "api/authApi";


const PasswordChangeForm = (props) => {
  const [passwordData, setPasswordData] = useState(false)
  const [passwordChange, setPasswordChange] = useState(false)
  const {setAppMsg} = useContext(AppContext)
  const {PasswordReset, handlePasswordReset} = props

  const [updateButton, setUpdateButton] = useState(passwordData.password && passwordData.passwordConfirm && passwordData.password === passwordData.passwordConfirm)

  const [passwordNoMatch, setPasswordNoMatch] = useState(passwordData.password?.length && passwordData.passwordConfirm?.length && passwordData.password === passwordData.passwordConfirm)


  const handlePasswordData = (e) => {
    setPasswordData({
      ...passwordData,
      [e.target.id] : e.target.value
    })
  }

  const showPasswordForm = () => {
    setPasswordChange(!passwordChange)
    setPasswordData(false)
  }

  const handlePasswordChange = async (passwordData) => {   
    if (PasswordReset) {
      handlePasswordReset(passwordData)
    } else {
      if (passwordData && passwordData.password === passwordData.passwordConfirm) {
        await authApi.changePassword(passwordData).then((res) => {
          if (res.status === 'success' ) {
            setPasswordChange('')
          }
        })
      }
      if (passwordData && passwordData.password !== passwordData.passwordConfirm) {
        setAppMsg({
          state: true,
          class: 'error',
          msg: 'Passwords do not match!'
          
        })
      }
      if (!passwordData) {}      
    }
  }

  useEffect(() => {
    setUpdateButton(passwordData.password && passwordData.passwordConfirm && passwordData.password === passwordData.passwordConfirm)

    setPasswordNoMatch(passwordData.password?.length && passwordData.passwordConfirm?.length && passwordData.password !== passwordData.passwordConfirm)

  }, [handlePasswordData])


  return (
    <form className="form my-account" onSubmit={(e) => e.preventDefault()}>
    <div className="form--content">
      <h2>{PasswordReset ? 'Reset Password' : 'Change Password' }</h2>
      <div className="fields">    
          {passwordChange && <>            

           {!PasswordReset && <InputPassword id={'passwordCurrent'} name={'password'} placeholder={'Current password'} formData={handlePasswordData} suggested={'password'}/>          }

            <InputPassword id={'password'} name={'password'} placeholder={'New Password'} suggested={'new-password'} formData={handlePasswordData}/>          

            <InputPassword id={'passwordConfirm'} name={'password'} placeholder={'Confirm new password'} suggested={'new-password-confirm'} formData={handlePasswordData}/>          
            
            {passwordChange && <>
              <div className="btnGroup btnGroup-flex center">
                {updateButton && <button className="btn" onClick={() => {handlePasswordChange(passwordData)}}>Update Password</button>}
                <button className="btn" onClick={showPasswordForm}>Cancel</button>
              </div>
              {!!passwordNoMatch && <p style={{display: 'flex', justifyContent: 'center', color: 'red', margin: '1rem auto', fontWeight: 'bold'}}>Passwords dont match</p>}
            </>}
            
            

          </>}


          <div className="btnGroup">
            {!passwordChange && <button className="btn" onClick={showPasswordForm}>Change Password</button>}
          </div>
      </div>
    </div>
  </form>


  )
}


export default PasswordChangeForm