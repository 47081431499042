import React, {useEffect, useState} from "react";


const TextArea = (props) => {
  const {label, id, type, placeholder, value, formData, span} = props
  const [inputValue, setInputValue] = useState(value)

  const handleOnChange = (e) => {
    setInputValue(e.target.value)
    formData(e)
  }

  useEffect(() => {
    setInputValue(value)
  }, [value])

  return (
    <div className={`form-group ${id}`}>
      {label && <label htmlFor={id} >{label}&nbsp;<span><i>{span}</i></span></label>}
      <textarea type={type} id={id} value={inputValue} placeholder={placeholder} onChange={(e)=>{handleOnChange(e)}} />
    </div>
  )
}

export default TextArea