import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import { AppProvider } from './context/Appcontext';
import { AuthProvider } from './context/AuthProvider';
import { PortfolioProvider } from './context/PortfolioProvider';
import { BrowserRouter } from 'react-router-dom';
import { AxiosInterceptor } from './context/ApiInstance'

import './index.scss';
  
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render( 
  
  <BrowserRouter>
      <AuthProvider>
        <PortfolioProvider>
          <AppProvider>
            <AxiosInterceptor>                   
              <App />
            </AxiosInterceptor>
          </AppProvider>
        </PortfolioProvider>
      </AuthProvider>
  </BrowserRouter>


);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
