import React, {useState, useContext, useEffect} from "react";

import PortfolioContext from "context/PortfolioProvider";
import Card from "components/Card/Card";
import {ReactComponent as Chevron} from  'icons/fa-chevron-right.svg';



const Employment = ({props}) => {
  const {onChange, editingPortfolio} = props
  const {employment, getUserEmployment} = useContext(PortfolioContext)
  const [open, setOpen] = useState(true)
  const [included, setIncluded] = useState([])  

  const addToPortfolio = (e, id) => {
    if (!included.includes(id)) {included.push(id)}    
    onChange('employment', included)    
  }

  const removeFromPortfolio = (e, id) => {
    if (included.includes(id)) {
          let newList = included.filter((item_id) => {
          return item_id !== id
        })              
        if (newList.length === 0) newList = []
        setIncluded(newList)
        onChange('employment', newList)
    }
  }

  const getSavedEmployment = () => {
    editingPortfolio.employment.map((job) => {      
      setIncluded(included => [...included, job.id])
      return
    })
  }

  useEffect(() => {
    if (!employment) {
      getUserEmployment()
    }
    getSavedEmployment()    
    return    
  }, [])


  return (
    <section className={`edit-portfolio ${open ? 'open' : ''}`}>
    <h3 onClick={() => setOpen(!open)}><Chevron className={open ? 'open' : ''}/> 
    Link your employment</h3>

    <div className="fields container">
    {employment && employment.map((job, index) => {
        return <>
          <Card key={job.id}>
            <div className="information edu_width">
              <h4>{job.CompanyName}</h4>
              <span>{job.StartDate} - {job.StillEmployed ? 'Current' : job.EndDate}</span>
            </div>
            <div className="information">
              <h4>{job.JobTitle}</h4>              
              <span>{job.Location}</span>
            </div>
            <div className="information">
              <p>{job.Description}</p>
            </div>

            <div className="btnGroup btnGroup-flex">
            {!included.includes(job.id) &&	<button className="btn action" onClick={(e) => addToPortfolio(e, job.id)}>Add</button>}
            {included.includes(job.id) && <button className="btn danger" onClick={(e) => removeFromPortfolio(e, job._id)}>Remove</button>}
            </div>				            
          </Card>
        </>
      })}
    </div>

    </section>

  )
}

export default Employment