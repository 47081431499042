import React, {useEffect, useState} from "react";
import validator from 'validator';

const InputField = (props) => {
  const {label, id, type, placeholder, value, formData, checked, span, suggested, name, index, min, max, defaultValue, step} = props
  const [inputValue, setInputValue] = useState(value)
  const [invalid, setInvalid] = useState(false)
  const [rangeValue, setRangeValue] = useState(defaultValue)

  
  // if(!pattern.test(url)) {
    //   url = "http://" + url;
    // }
    
    const urlValidatorOptions = {
      protocols: ['https'],
      require_valid_protocol: true,
      allow_protocol_relative_urls: false,
      allow_query_components: true,
      require_tld: true
    }    
    
    const handleOnChange = (e) => {    
      const pattern = /^((http|https|ftp):\/\/)/;
      if (type === 'url') {
        if ( (e.target.value && !pattern.test(e.target.value)) || (e.target.value && !validator.isURL(e.target.value, urlValidatorOptions)) ) {
          setInvalid('Please enter a valid url. https://www.example.com')
          setInputValue(e.target.value);
        } else {
          setInvalid(false)
          setInputValue(e.target.value);
          formData(e)
        }
      } else if (type === 'email') {
        if (e.target.value && !validator.isEmail(e.target.value)) {
          setInvalid('Please enter a valid email. hello@example.com')
          setInputValue(e.target.value);
        } else {
          setInvalid(false)
          setInputValue(e.target.value);
          formData(e)
        }

      } else {
        formData(e)
        setInputValue(e.target.value);
      }
      if (type === 'range') setRangeValue(e.target.value)
  }

  useEffect(() => {
    setInputValue(value)
  }, [value])

  return (
    <div key={index} className={`form-group ${id}`}>
      {type !== 'range' && <>
        {label && <label htmlFor={id} >{label}&nbsp;<span><i>{span}</i></span></label>}

        <input type={type} name={name} id={id} placeholder={placeholder} value={inputValue || ''} autoComplete={suggested} onChange={(e)=>{handleOnChange(e)}} checked={checked} /> 
      </>}

      {type === 'range' && <>
        {label && <label htmlFor={id}>{label}&nbsp;<span style={{fontSize: '1.2rem'}}><i>{rangeValue}%</i></span></label>}<br/>
        <input type={type} name={name} id={id} placeholder={placeholder} onChange={(e)=>{handleOnChange(e)}} min={min} max={max} defaultValue={defaultValue} step={step} style={{width: '100%', cursor: 'pointer'}}/>
        </>}
        

      {invalid && <p style={{color: 'red'}}>{invalid}</p>}      
    </div>
  )
}

export default InputField