import React, {useState, useEffect} from "react";
import {ReactComponent as TitleIcon} from 'icons/fa-id-card.svg';
import PasswordChangeForm from "pages/myAccount/PasswordChangeForm";
import authApi from "api/authApi";

import 'scss/index.scss'



const PasswordReset = () => {
  const [token, setToken] = useState(false)

  const getToken = (window) => {
    setToken(window.location.pathname.split('/')[2])
  }
  
  const handlePasswordReset = async (data) => {
    await authApi.passwordReset(token, data).then((res) =>{
      if (res.status === 'success') {
        window.location.href= '/'
      }
    })
  }

  useEffect(() => {
    getToken(window)
  }, [])

  return (
    <main>
      <div className="container">
        <div className="content">
          <div id="modules">
            <div className="module">


            <div className="module--title">
              <TitleIcon className="titleIcon"/>
              <h4>Password Reset</h4>
            </div>

            <div className="my-account--form-group">      
              <PasswordChangeForm  PasswordReset={true} handlePasswordReset={handlePasswordReset}/>
            </div>   

            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default PasswordReset