import React, { useContext} from 'react'

import AuthContext from 'context/AuthProvider';

import './Header.scss';

const Header = () => {
  const {auth} = useContext(AuthContext);

  return (
    <header>

      <div className="header-contents">
        <div className="title">
          <p>Logged in as: {auth?.name}&nbsp;<span>{auth.roles === 'admin'? `| ${auth.roles.charAt(0).toUpperCase()+ auth.roles.slice(1)}`: ''}</span></p>                  
          
        </div>     
      </div>

      {/* <div className="header-btns">   
        <div className="btn-container">
          <button className="btn">View Live Portfolio</button>
        </div>
      </div> */}


    </header>
  )
}

export default Header