import React from "react";

import UserInfoChangeForm from "./UserInfoChangeForm";
import PasswordChangeForm from "./PasswordChangeForm";

import {ReactComponent as TitleIcon} from 'icons/fa-id-card.svg';
import PNPermissionButton from "components/Notifications/PNPermissionButton"; 
import './myAccount.scss';

const MyAccount = () => {  

  return (
  <div className="module">
    <div className="module--title">
      <TitleIcon className="titleIcon"/>
      <h4>My Account</h4>
    </div>
      <PNPermissionButton />

    <div className="my-account--form-group">
      <UserInfoChangeForm />
      <PasswordChangeForm />
    </div>   

    <div>
    </div>

  </div>

  )
}

export default MyAccount