import React, {useContext, useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";

import Login from "pages/login";
import AppContext from "context/Appcontext";
import Card from "components/Card/Card";

import background from 'images/bg-img.png'
import Footer from "./Footer";

const Unpublished = () => {
  const {setAppMsg} = useContext(AppContext)
  const navigate = useNavigate();


  useEffect(() => {
    setAppMsg({
      state: true,
      msg: 'This portfolio was retracted by the author.',
      color: 'error'
    })
    setTimeout(() => {
      window.location.href ='/'
    }, 2000)
  }, [])

  const modulesBg = {
    backgroundImage: `linear-gradient(rgba(255,255,255,1), rgba(255,255,255,0.6)),url(${background})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  }

  
  return (
    <div style={modulesBg}>
        <Card><h4>This portfolio was retracted by the author.</h4></Card>        
    </div>
  
  )

}


export default Unpublished