import React, { useEffect, useState } from "react";

import adminRole from "api/adminRole";

import Card from "components/Card/Card";

const UserPortfolios = () => {
  const [allPortfolios, setAllPortfolios] = useState(false)

  const getAllPortfiolios = async () => {
    await adminRole.getAllPortfiolios()
    .then((res) => {     
      setAllPortfolios(res.data)      
    })        
  }
  
  useEffect(() => {
    getAllPortfiolios()    
  }, [])


  return (
    <>
    {allPortfolios && allPortfolios.map((port, index) => {
      return <span key={port.id}> 
      <Card classes={'align-left'}>
        <b>{port.user.name}, {port.title}</b> <br /> {port.email},

        <div className="btnGroup btnGroup-flex">
          <button className="btn">View</button>
          <button className="btn">Edit</button>
          <button className="btn">Delete</button>
        </div>
      </Card> 
      </span>
    })}
    </>
  )
}

export default UserPortfolios