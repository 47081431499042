import React, { useContext, useState } from "react";

import PortfolioContext from "context/PortfolioProvider";
import api from 'api/employmentApi';

import Card from "components/Card/Card";
import Modal from "components/Modal/Modal";
import ConfirmDelete from "components/ConfirmDelete/ConfirmDelete";

import Job from './Job'

import {ReactComponent as TitleIcon} from 'icons/fa-briefcase.svg';

const Employment = () => {
  const {employment, getUserEmployment} = useContext(PortfolioContext);
  const [addJob, setAddJob] = useState(false)
  const [toEdit, setToEdit] = useState(false)

  const handleAddJob = (edu) => {
    setAddJob(!addJob)      
  }

  const handleEditJob = (job) => {
    setAddJob(!addJob)  
    setToEdit(job)      
  }

  const handleDeleteJob = async (id) => {    
    await api.deleteEmployement(id).then(getUserEmployment())
  }  

  const converDate = (date) => {
    const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"];
    let month = new Date(date).getMonth();
    let year = new Date(date).getFullYear() 
    if (month < 0 || month == 11) {
      month = 0
      year++
    } else {
      month++
    }
    
    return `${monthNames[month]}, ${year}`
  }


  return (
    <div className="module">
      <div className="module--title">
        <TitleIcon className="titleIcon"/>
        <h4>My Employment</h4>
      </div>

      {employment && employment.map((job, index) => {
        return <Card key={job.id}>
            <div className="information edu_width">
              <h4>{job.CompanyName}</h4>
              <span>{converDate(job.StartDate)} - {job.StillEmployed ? 'Current' : converDate(job.EndDate)}</span>
            </div>
            <div className="information">
              <h4>{job.JobTitle}</h4>              
              <span>{job.Location}</span>
            </div>
            <div className="information">
              <p>{job.Description}</p>
            </div>

            <div className="btnGroup btnGroup-flex">
								<button className="btn warning" onClick={()=>handleEditJob(job)}>Edit</button>
                <ConfirmDelete item={job} delFunction={handleDeleteJob} />
            </div>						                        
          </Card>        
      })}
      {!addJob && <button className="btn btn-sticky--bottom" onClick={handleAddJob}>+ New Employment</button>
      }

      {addJob && <Modal>
        <Job getUserEmployment={getUserEmployment} handleAddJob={handleAddJob} editing={toEdit} setEditing={setToEdit}/>
      </Modal>}

    </div>
  )
}

export default Employment