import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";

// Context
import PortfolioProvider from 'context/PortfolioProvider'
import AppContext from "context/Appcontext";

// Api
import portfolioApi from "api/portfolioApi";

// Components
import SharePortfolio from "./SharePortfolio";
import Card from "components/Card/Card";
import ConfirmDelete from "components/ConfirmDelete/ConfirmDelete";
import ImgUpload from "components/imgUpload/imgUpload";
import EditPortfolio from "pages/editPortfolio/index"


import {ReactComponent as GearIcon} from 'icons/fa-gear.svg';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserTie } from "@fortawesome/free-solid-svg-icons";

const Portfolios = () => {   
  const {portfolio, getUsersPortfolios} = useContext(PortfolioProvider)    
  const [editing, setEditing] = useState(false)

  const newPortfolio = async () => {        
    await portfolioApi.newPortfolio()
    .then((res) => {
      if (res.status === 'success') {
        getUsersPortfolios();              
      }
    })
  }

  const handleDuplicate = async (data) => {
    data.id = null
    data._id = null
    data.active = false
    await portfolioApi.newPortfolio(data).then((res) => {
      if (res.status === 'success') {
        getUsersPortfolios();              
      }
    })
  }

  const deleteController = async (id) => {      
    await portfolioApi.deletePortfolio(id)
    .then((res) => {
      getUsersPortfolios()      
    })    
  }

  const setActivePortfolio = async (portfolio) => {     
    const active = portfolio.active ? portfolio.active = false : portfolio.active = true;
    const {id} = portfolio
    await portfolioApi.setActive(id, {active: active})      
    getUsersPortfolios()    
  }    

  const handleEditing = (portfolio) => {
    setEditing(portfolio)
  }

  return (        
    <div className="module">
      <div className="module--title">
        <FontAwesomeIcon icon={faUserTie} className="titleIcon" />
        <h4>My Portfolios</h4>
      </div>  
      
      
      {portfolio &&         
        portfolio.map((portfolio) => {                
          return <Card key={portfolio.id}>            
              <div className="img">
                <ImgUpload editingPortfolio={portfolio}/>      
              </div>
              <div className="information">
                <h5>{portfolio.name}</h5>
                <h6>{portfolio.title}</h6>
                <h6 style={{color: portfolio.active? 'green' : 'red'}}>{portfolio.active ? "published" : "unpublished"}</h6>
                {portfolio.active && <SharePortfolio portfolio={portfolio} />}
              </div>
              <div className="btnGroup btnGroup-flex">               
                <Link to={'../../../previewportfolio'} state={{ portfolio }} className='btn'>View</Link>
                  <button className="btn action" onClick={()=> {handleDuplicate(portfolio)}}>Duplicate</button>
                  <button className="btn warning" onClick={()=>handleEditing(portfolio)}>
                    edit
                  </button>                                    

                <ConfirmDelete item={portfolio} delFunction={deleteController} />

                {!portfolio.active && <button className="btn action" onClick={() => setActivePortfolio(portfolio)}>publish</button>}
                {portfolio.active && <button className="btn danger" onClick={() => setActivePortfolio(portfolio)}>unpublish</button>}
                {/* I will eventually do something with this. Or... not */}
                {/* <div className="icon icon_settings"><GearIcon /></div> */}
              </div>                 
            </Card>              
        })         
      }   
      {portfolio && portfolio.length <= 4 && <button className="btn btn-sticky--bottom" onClick={newPortfolio}> + New Portfolio </button>}      

    {editing && <EditPortfolio editingPortfolio={editing} handleEditing={handleEditing}/>}

    </div>   

  )
}

export default Portfolios