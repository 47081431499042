import React, { useContext, useEffect, useState } from "react";
import {useLocation, useNavigate } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";

import AppContext from "context/Appcontext";

import Loading from "components/Load/Loading";
import Navigation from "components/Navigation/Navigation";
import SocialMediaLinks from "./SocialMediaLinks";
import portfolioApi from "api/portfolioApi";

import Hero from './Hero';
import About from './About';
import Skills from "./Skills";
import Resume from './Resume';
import Projects from './Projects';
import Footer from "./Footer";

import Unpublished from "./Unpublished";

import 'scss/index.scss'
import 'bootstrap/dist/css/bootstrap-grid.min.css'
import background from 'images/bg-img.png'


const MyPortfolio = (props) => {
  const navigate = useNavigate()
  const {setLoading, setAppMsg} = useContext(AppContext)
  const {state} = useLocation();
  const [data] = useState(state?.portfolio)
  const [portfolio, setPortfolio] = useState(data)
  const [userView, setUserView] = useState(false)
  const [backgroundImage, setBackgroundImage] =useState()  

  const handleGetPortfolio = () => {
    let id = window.location.pathname.split('/')[2]
    if (!id) id = '652de5eef14683f67fd6712c'
    portfolioApi.getPublishedPortfolio(id).then((res) => {
      if (res.status === 200) {
        setTimeout(function() {
          setPortfolio(res.data.portfolio)
        },1000)
      }      
    }).catch((err => {
      setPortfolio("not-published")
    }))
  } 

  useEffect(() => {
    setPortfolio(data)
    Aos.init()
    Aos.refresh()    
  }, [])

  useEffect(() => {    
    if (portfolio) {
      setUserView(true)
      portfolio.backgroundImage ? setBackgroundImage(portfolio.backgroundImage) : setBackgroundImage(`linear-gradient(rgba(255,255,255,1), rgba(255,255,255,0.6)),url(${background})`)
    }    
  },[portfolio])  

  useEffect(() => {
    setLoading(true)
    if (!portfolio) handleGetPortfolio()
    if (portfolio === 'not-published') {
      setAppMsg({
        state: true,
        msg: 'Portfolio was retracted by author',
        class: 'error'
      })
    }

  },[])   

  const modulesBg = {
    backgroundImage: `linear-gradient(rgba(255,255,255,1), rgba(255,255,255,0.6)),url(${background})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
  }

  if (portfolio === 'not-published') return <Unpublished />
  if (!portfolio) return <Loading />
  
  return (          
    <main id="portfolio-viewer">        
      <Navigation portfolio={portfolio} page={'myportfolio'}
       portfolioLinks={<SocialMediaLinks portfolio={portfolio} />
       } /> 
      
      
      <div className="wrapper" style={modulesBg}>
        <section className="content">
          <section id="modules">


              <Hero portfolio={portfolio}/>
              <About portfolio={portfolio}/>      
              {!!portfolio.education.length && !!portfolio.employment.length && <Resume portfolio={portfolio}/>}
              {!!portfolio.skills.length && <Skills portfolio={portfolio}/>}
              {!!portfolio.projects.length && <Projects portfolio={portfolio}/>}
              
          </section>
              <Footer portfolio={portfolio} />
        </section>
      </div>
    </main>
    
    
  )
}

export default MyPortfolio;