import React from "react";

import 'components/Navigation/MobileNavButton.scss'

const MobileNavButton = (props) => {
  const {handleNav, active} = props  

  return (
    <div onClick={handleNav} className={`${active ? 'open' : '' } mobile-nav`}>
      <div></div>
    </div>
  )
}

export default MobileNavButton