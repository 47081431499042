import React, {useState, useEffect} from "react";
import validator from "validator";

import {ReactComponent as FaEye} from 'icons/fa-eye.svg';
import {ReactComponent as FaEyeSlash} from 'icons/fa-eye-slash.svg';

import './InputPassword.scss'

const InputPassword = (props) => {
  const {label, id, value, placeholder, name, formData, checked, span, suggested} = props
  const [inputValue, setInputValue] = useState(value)
  const [inputType, setInputType] = useState('password')
  const [error, setError] = useState(false)

  const passwordOptions = {
    minLength: 8,
    minLowercase: 1,
    minUppercase: 1,
    minSymbols: 1
  }

  const handleOnChange = (e) => {
    if (e.target.value && !validator.isStrongPassword(e.target.value, passwordOptions)){
      setInputValue(e.target.value)
      setError('Passwords must be at least 8 characters long, contain 1 lower case value and one upper case value')

      //formState.password, formState.passwordConfirm    
    } else {
      setInputValue(e.target.value)
      setError(false)
      formData(e)
    }
  }

  const showPassword = () => {
    inputType === 'password' ? setInputType('text') : setInputType('password')
  }

  useEffect(() => {
    setInputValue(value)
  }, [value])

  return (
    <div className={`form-group ${id}`}>
      {label && <label htmlFor={id} >{label}&nbsp;<span><i>{span}</i></span></label>}
      <div className="password-container">
        <input type={inputType} id={id} name={name} value={inputValue || ''} placeholder={placeholder} onChange={(e)=>{handleOnChange(e)}} autoComplete={suggested} />
        <button className="btn" onClick={showPassword}>
        {inputType !== 'password' ? <FaEye /> : <FaEyeSlash/> } 
        </button>
      </div>
      {error && <p style={{color: 'red', fontSize: '1.4rem', textAlign: 'center'}}>{error}</p>}
    </div>
  )
}

export default InputPassword