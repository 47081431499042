import React from "react";

import Card from "components/Card/Card";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCode } from '@fortawesome/free-solid-svg-icons'

const gridStyles = {
  width : '100%',
  maxWidth: '100%',
  display : 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(25rem, 1fr))',
  columnGap: '3rem'
}


const progressWidth = {
  width: '100%',
  maxWidth: '100%'
}

const Skills = (props) => {
  const {portfolio} = props;
  const overFlow = {overflow: 'hidden'}

  return(

    <div className="module" id="skills" style={overFlow}>
      <div className="content">
      <div className="section-title" data-aos="fade-down" data-aos-duration="1000">
        <FontAwesomeIcon icon={faCode} className="titleIcon"/>
        <h2>Skills</h2>
      </div>    

      <div className="container" data-aos="fade-up" data-aos-duration="1000">      

        {portfolio.skills && <Card>
          <div style={gridStyles}>
            {portfolio.skills && portfolio.skills.map((skill, index) => {
                  return <div key={index} style={{marginBottom: '1rem', width: '90%'}}>
                    <label htmlFor={skill.skill}>{skill.skill} </label><br/>    
                    <progress style={progressWidth} value={skill.skillMastery} max={'100'}></progress>
                  </div> 
                  })}           
          </div>
        </Card>}               
        </div>
        </div>
      </div>
    

  )
}

export default Skills