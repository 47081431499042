import React, {useContext, useEffect, useState} from "react";
import {ReactComponent as Chevron} from  'icons/fa-chevron-right.svg';
import Card from "components/Card/Card";
import PortfolioContext from "context/PortfolioProvider";

const Projects = ({props}) => {
  const {onChange, editingPortfolio} = props
  const [open, setOpen] = useState(true)  
  const {projects, setProjects, getMyProjects} = useContext(PortfolioContext)
  const [included, setIncluded] = useState([])  

  const getSavedProjects = () => {
    editingPortfolio.projects.map((project) => {
      setIncluded(included => [...included, project.id])
      return
    })
  }

  const addToPortfolio = (e, id) => {    
    if (!included.includes(id)) {included.push(id)}    
    onChange('projects', included)
  }

  const removeFromPortfolio = (e, id) => {
    if (included.includes(id)) {
          let newList = included.filter((item_id) => {
          return item_id !== id
        })              
        if (newList.length === 0) newList = []
        setIncluded(newList)
        onChange('projects', newList)
        return
    }
  }

  useEffect(() => {
    if (!projects) {
      getMyProjects()      
    }
    getSavedProjects()    
    return
    //eslint-disable-next-line
  }, [])

  
  return (
    <section id="projects" className={`edit-portfolio ${open ? 'open' : ''}`}>
        <h3 onClick={() => setOpen(!open)}><Chevron className={open ? 'open' : ''}/> Link your projects</h3>
        
        <div className="fields container">             
          {projects && projects.map((project, index) => {
            return (
              <Card id={project.id} index={project.id} classes={'project_card'}>	
                <div className="img" style={{maxWidth: '100%', width: '100px'}}>							
                  <img src={project.projectThumbnailUrl} alt={project.projectName} />
                </div>
                <div className="information">
                  <a href={project.projectLink} target="_blank" rel="noreferrer">{project.projectName}</a> <br />
                  <span><i>{project.projectCategory}</i></span>
                  
                  <ul className="skill">{project.skillsUsed.map((skill, index2) => {
                    return <li key={`skill_${index2}`}>{skill}</li>
                  })}</ul>
                </div>
                <div className="btnGroup btnGroup-flex">
                  {!included.includes(project._id) && <button className="btn action" onClick={(e) => addToPortfolio(e, project._id)}>Add</button>}
                  {included.includes(project._id) && <button className="btn danger" onClick={(e) => removeFromPortfolio(e, project._id)}>Remove</button>}
                </div>                                		
            </Card>
    
            )
          })}
        

        

        </div>
        

    </section>
  )
}

export default Projects