import React, {useState, useEffect} from "react";

import api from 'api/educationApi'

import InputText from "components/Forms/Input";
import TextArea from "components/Forms/TextArea";


const Edu = (props) => {
  const {getUserEducation, handleAddEdu, editing, setEditing} = props;
  const [formData, setFormData] = useState(false)

  const onChangeHandler = (e) => {    
    setFormData({
      ...formData,
      [e.target.id] : e.target.value
    })   
  }

  const handleSaveEdu = async (e) => {
    e.preventDefault()
    await api.newUserEducation(formData).then((res) => {      
      if (res.status === 'success') {
        getUserEducation()    
        handleAddEdu()
      }
    })   
  }

  const handleEdit = async (e) => {
    e.preventDefault()  
    const {id} = formData    
    await api.updateEducation(id, formData).then(res => {
      if (res.status === 'success') {
        getUserEducation()
        setEditing(false)
        handleAddEdu()
      }
    })
  }

  const handleCancel = () => {
    setEditing(false)
    handleAddEdu()
  }

  useEffect(() => {        
    if (editing) {
      setFormData(editing)
    }    
  }, [])

  const saveButton = formData.SchoolName && formData.Location && formData.Degree

  return (
    <form className="form edu">
      {!editing && <h1>Education Details</h1>}
      {editing && <h1>Editing {formData.SchoolName}</h1>}
      <div className="form--content">
        <div className="fields">

          <InputText label="School Name" type="text" id="SchoolName"
          value={formData.SchoolName} placeholder={'School of Hard Knocks'} formData={onChangeHandler}/>

          <InputText label="Location" type="text" id="Location"
          value={formData.Location} placeholder={'1327 Los Angeles'} formData={onChangeHandler}/>

          <InputText label="Degree" type="text" id="Degree"
          value={formData.Degree} placeholder={'Masters'} formData={onChangeHandler}/>

          <InputText label="Field" type="text" id="Field"
          value={formData.Field} placeholder={'Bakery Science'} formData={onChangeHandler}/>

          <InputText label="Graduation Year" type="month" max={4} id="GraduationYear"
          value={formData.GraduationYear ? formData.GraduationYear : ''} formData={onChangeHandler}/>

          <TextArea label={'Details'} id={'details'} value={formData.details} formData={onChangeHandler}/>




        </div>
        <div className="btnGroup btnGroup-flex center">
            <button className="btn" onClick={handleCancel}>Cancel</button>
            {saveButton && <button className="btn" onClick={ editing ? (e) => {handleEdit(e)} : (e) => handleSaveEdu(e)}>Save</button>}
          </div>

      </div>
    </form>
  )
}

export default Edu