import React from "react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDiagramProject } from "@fortawesome/free-solid-svg-icons";
import Card from "components/Card/Card";

const Projects = (props) => {
  const {portfolio} = props;

  
  return (        


  <div className="module" id="projects">
      <div className="section-title" data-aos="fade-down" data-aos-duration="1000">
        <FontAwesomeIcon icon={faDiagramProject} className="titleIcon"/>
        <h2>Projects</h2>
      </div>    

        <div className="row" data-aos="fade-up" data-aos-duration="1000">          
            {portfolio.projects.map((project, index) => {
              return (
                <Card classes={'project_card'} key={index}>
                  <div className="img" style={{maxWidth: '100%', width: '100px'}}>							
								    <img src={project.projectThumbnailUrl} alt={project.projectName} />
                  </div>
                  <div className="information">
                    <Link to={project.projectLink} target="_blank" rel="noreferrer">{project.projectName}</Link> <br />
                    <span><i>{project.projectCategory}</i></span>	
                    <p>{project.details}</p>						
								
                      <ul className="skill">{project.skillsUsed.map((skill, index2) => {
                          return <li key={`skill_${index2}`}>{skill}</li>
                      })}</ul>
                    </div>
                </Card>
                )
            })}
          </div>
        </div>
    
      
  )
}

export default Projects