import React, {useState} from "react";

import {ReactComponent as Chevron} from  'icons/fa-chevron-right.svg';
import InputField from "components/Forms/Input";
import TextArea from "components/Forms/TextArea";


const AboutInfo = ({props}) => {
  const {onChange, formState} = props
  const [open, setOpen] = useState(true)

  const handleAboutInfo = (e) => {
    onChange(e)
  }


  return (
    <section className={`edit-portfolio ${open ? 'open' : ''}`}>
        <h3 onClick={() => setOpen(!open)}><Chevron className={open ? 'open' : ''}/> About Information</h3>
         
        <div className="fields container">        

          <InputField id={'degree'} label={'Degree'} 
          type={'text'} value={formState.degree} formData={handleAboutInfo} />

          <InputField id={'title'} label={'Job title.'} span={'Possition you are apploying for.'}
          type={'text'} value={formState.title} formData={handleAboutInfo} />

          <InputField id={'status'} label={'Current Status'} span={'Employed, College Student...'} type={'text'} value={formState.status} placeholder={'Currently employed'} formData={onChange}/>


          <TextArea id={'blurb'} label={'Why are you applying for this position'} span={'This is a blurb, firts thing paragraph in about section'} type={'text'} value={formState.blurb}  formData={handleAboutInfo} />  

          <TextArea id={'blurbTwo'} label={'Tell me about yourself.'} span={'Second blurb under position title.'} type={'text'} value={formState.blurbTwo} formData={handleAboutInfo} />


          <TextArea id={'aboutme'} label={'Close it up or make them laugh.'} span={'Shows just under email, city...'} type={'text'} value={formState.aboutme}  formData={handleAboutInfo} />  


          <TextArea label={'Tell the employer about yourself.'} span={'Intro paragraph on your resume.'} id={'intro'} type={'text'} value={formState.intro} formData={handleAboutInfo} />


        </div>
        

    </section>
  )
}

export default AboutInfo