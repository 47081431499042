import React, {useState, useContext} from "react";
import { useNavigate, Link } from "react-router-dom";

import AppContext from "../../context/Appcontext";
import  AuthContext from '../../context/AuthProvider';

import authApi from '../../api/authApi'
import AppMsgModule from "../../components/AppMsgModule/AppmsgModule";
import InputPassword from "components/Forms/InputPassword";
import InputField from "components/Forms/Input";

import background from 'images/bg-img.png'


import './index.scss';

const Login = () => {  
  const navigate = useNavigate()
  const {setAuth, auth} = useContext(AuthContext);   
  const {setAppMsg} =useContext(AppContext)   
  const [formState, setFormState] = useState({});  
  const [login, setLogin] = useState(true);
  const [register, setRegister] = useState(false);
  const [forgotPass, setForgotPass] = useState(false);

  const registerAccount = (e) => {
    e.preventDefault()
    setRegister(true)
    setLogin(false)
  }  

  const haveAccount = (e) => {
    e.preventDefault()
    setRegister(false)
    setLogin(true)
  }

  const handleForgotPass = () => {
    setForgotPass(!forgotPass)
  }

  const onChange = (e) => {    
    setFormState({
      ...formState,
      [e.target.id]: e.target.value
    })
  }  
  
  const onSubmit = async (e) => {
    e.preventDefault()
    let response;    
      if (login && !forgotPass) {
        response = await authApi.login(formState.email, formState.password);        
      }
      if (register) {    
        if (!formState.name) {return setAppMsg({state: true, msg: 'Please provide your name', class: 'error'})}   
        if (!formState.email) {return setAppMsg({state: true, msg: 'Please provide your email', class: 'error'})}   
        if (formState.password !== formState.passwordConfirm) {return setAppMsg({state: true, msg: 'Passwords do not match', class: 'error'})}   
        response = await authApi.register(formState.name, formState.email, formState.password, formState.passwordConfirm);
      }   
      if (forgotPass) {
        await authApi.fogotPassword(formState.email).then((res) => {        
          if (res.status === 'success') {
            setAppMsg({
              state: true,
              msg: res.message,
              class: 'success'
            })           
          }
        })
      }  
      
      if ((login && !forgotPass) || register) {        
        if (response.status === 'success') {
          const {email, id, name, roles, Instructions} = response.data.user;
          setAuth({email, id, name, roles, Instructions});
          const location = window.location.pathname              
          if (location === '/' || location === '/login') return navigate(Instructions ? '/setup': '/setup/portfolios')
          navigate(location)
         
        }    
        if (response.status === 401) {
          setAppMsg({
            state: true,
            msg: `${response.message}. ${response.error}`,
            class: 'error'
          })
        }
      }
  }

  const modulesBg = {
    backgroundImage: `linear-gradient(rgba(255,255,255,1), rgba(255,255,255,0.6)),url(${background})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
  }

  return (
    <>
    <div className="login-container" style={modulesBg}>    
      <form className="login form" onSubmit={(e)=>e.preventDefault()}>
        {login && <h1>Login</h1>}
        {register && <h1>New Account</h1>}
        <div className="form--content">
          <div className="fields">
            {register &&  <>              
              <InputField id={'name'} label={'Name'} name={'username'} placeholder={'Your name'} type={'text'} suggested={'name'} formData={onChange} />
            </>}
            
            <InputField id={'email'} label={'Email'} name={'email'} placeholder={'hello@example.com'} type={'email'} suggested={'email'} formData={onChange} />
            
            {!forgotPass && <>
              {!register && <InputPassword id={'password'} name={'password'} label={'password'} formData={onChange} suggested={'password'} required />}            
            </>}
            
            {register &&  <>
              <InputPassword id={'password'} name={'password'} label={'Password'} suggested={'new-password'} formData={onChange}/>   
              <InputPassword id={'passwordConfirm'} name={'passwordConfirm'} label={'Confirm Password'} suggested={'new-password-confirm'} formData={onChange}/>   
            </>}              
            

              <button className="btn" onClick={onSubmit}>{forgotPass ? 'Send me instructions' : 'Submit'} </button>                        
              {!login && <button className="btn" onClick={(e) => {haveAccount(e)}}>I have an account</button>}
              {!forgotPass && <>
                {login && <button className="btn" onClick={handleForgotPass}>Forgot Password</button>}                            
                {login && <button className="btn" onClick={(e) => registerAccount(e)}>New Account</button>}
              </>}
              {forgotPass && <button className="btn" onClick={handleForgotPass}>cancel</button>}
              <Link style={{textAlign: 'center', display:'block'}} to={'https://dewebsites.tech/privacy'} target="_blank">Privacy Policy</Link>
          
          </div>        
        </div>
      </form>
    </div>
        <AppMsgModule />
    </>
  )
}

export default Login