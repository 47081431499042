//https://dev.to/arianhamdi/react-hooks-in-axios-interceptors-3e1h
import axios from 'axios'
import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import AppContext from './Appcontext'

const api = axios.create({
  baseURL: window.location.origin,
  withCredentials: true,
  headers: {
    "Content-Type" : "application/json"    
  }
})

const AxiosInterceptor = ({ children }) => {
    const {setAppMsg} = useContext(AppContext)
    const navigate = useNavigate();

    useEffect(() => {        
        const resInterceptor = response => {             
            // setAppMsg({
            //     state: true,
            //     msg: response.data.message || response.data.status,
            //     class: 'success'
            // })
            return response.data;
        }

        const errInterceptor = error => {     
            if (error.response.status === 401) {
                //navigate('/login');
            }
            setAppMsg({
                state: true,
                msg: `${error.response.data.message} 
                ${error.response.data.error ? + ',' + error.response.data.error : '' }`,
                class: 'error'
            })

            //return Promise.reject(error);
            return error.response.data;
        }


        const interceptor = api.interceptors.response.use(resInterceptor, errInterceptor);

        return () => api.interceptors.response.eject(interceptor);

    }, [navigate])

    return children;
}


export default api;
export { AxiosInterceptor }