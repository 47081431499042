import React, {useContext, useState, useEffect} from "react";

import PortfolioContext from "context/PortfolioProvider";

import Card from "components/Card/Card";
import {ReactComponent as Chevron} from  'icons/fa-chevron-right.svg';


const EduInfo = ({props}) => {
  const {onChange, editingPortfolio} = props
  const {education, getUserEducation} = useContext(PortfolioContext)
  const [open, setOpen] = useState(true)
  const [included, setIncluded] = useState([])  


  const addToPortfolio = (e, id) => {
    if (!included.includes(id)) {included.push(id)}    
    onChange('education', included)    
  }

  const getSavedEducation = () => {
    editingPortfolio.education.map((edu) => {
      // included.push(edu.id)
      setIncluded(included => [...included, edu.id])
      return
    })
  }

  const removeFromPortfolio = (e, id) => {
    if (included.includes(id)) {
          let newList = included.filter((item_id) => {
          return item_id !== id
        })              
        if (newList.length === 0) newList = []
        setIncluded(newList)
        onChange('education', newList)
    }
  }

  useEffect(() => {
    if (!education) {
      getUserEducation()
    }
    getSavedEducation()    
    return
    //eslint-disable-next-line
  }, [])


  return (
    <section className={`edit-portfolio ${open ? 'open' : ''}`}>
    <h3 onClick={() => setOpen(!open)}><Chevron className={open ? 'open' : ''}/> 
    Link your education</h3>
    
    <div className="fields container">                
      {education && education.map((ed) => {
        return (
					<Card id={ed.id} key={ed.id}>											
							<div className="information edu_width">
								<h4>{ed.SchoolName}</h4>
                <span>{ed.Location}</span>																
							</div>
              <div className="information">
                <h4>{ed.Field}</h4>
                <span>{ed.Degree}, {ed.GraduationYear}</span>
              </div>
							<div className="btnGroup btnGroup-flex">
							{!included.includes(ed.id) &&	<button className="btn action" onClick={(e) => addToPortfolio(e, ed.id)}>Add</button>}
              {included.includes(ed.id) && <button className="btn danger" onClick={(e) => removeFromPortfolio(e, ed._id)}>Remove</button>}
              </div>						
					</Card>	
        )
      })}

    

    </div>
    

</section>

  )
}

export default EduInfo