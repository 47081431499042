
//Probably better to add this as a portfolio option and serve from db
const MyPortfolioLinks = [
  {
    module: 'Home',
    path: 'hero'
  },
  {
    module: 'About',
    path: 'about'
  },
  {
    module: 'Resume',
    path: 'resume'
  },
  {
    module: 'Projects',
    path: 'projects'
  }  
]

module.exports = MyPortfolioLinks