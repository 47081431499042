import React, { useContext, useEffect, useState } from "react";

import PortfolioContext from "context/PortfolioProvider";
import Card from "components/Card/Card";

import {ReactComponent as Chevron} from  'icons/fa-chevron-right.svg';



const Skills = ({props}) => {
  const {onChange, editingPortfolio} = props
  const {skills, getUserSkills} = useContext(PortfolioContext)
  const [open, setOpen] = useState(true)
  const [included, setIncluded] = useState([])  

  const addToPortfolio = (e, id) => {
    if (!included.includes(id)) {included.push(id)}    
      onChange('skills', included)    
  }

  const removeFromPortfolio = (e, id) => {
    if (included.includes(id)) {
          let newList = included.filter((item_id) => {
          return item_id !== id
        })              
        if (newList.length === 0) newList = []
        setIncluded(newList)
        onChange('skills', newList)
    }
  }

  const getSavedSkills = () => {
    if (editingPortfolio && editingPortfolio.skills) {
      editingPortfolio.skills.map((skill) => {      
        setIncluded(included => [...included, skill.id])
        return
      })
    }
    
  }


  useEffect(() => {
    if (!skills) {
      getUserSkills()
    } 
    getSavedSkills()
    return
  }, [])



  const progressWidth = {
    width: '100%',
    maxWidth: '100%'
  }

  return (
    <section className={`edit-portfolio ${open ? 'open' : ''}`}>
    <h3 onClick={() => setOpen(!open)}><Chevron className={open ? 'open' : ''}/> 
    Link your skills</h3>

    <div className="fields container">    
              {skills && skills.map((skill, index) => {
            return <Card>
            <div key={index} style={{marginBottom: '1rem', width: '90%'}}>
              <label htmlFor={skill.skill}>{skill.skill} </label><br/>    
              <progress style={progressWidth} value={skill.skillMastery} max={'100'}></progress>
            </div> 
              <div className="btnGroup btnGroup-flex">
                  {!included.includes(skill.id) &&	
                    <button className="btn action" onClick={(e) => addToPortfolio(e, skill.id)}>Add</button>}
                {included.includes(skill.id) &&
                     <button className="btn danger" onClick={(e) => removeFromPortfolio(e, skill.id)}>Remove</button>}
              </div>
            </Card>})}                   
    </div>
    </section>
    
  )
}

export default Skills