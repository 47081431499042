import React, { createContext, useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router";
import authApi from '../api/authApi'
import AuthProvider from "./AuthProvider";
import PortfolioContext from './PortfolioProvider';
import userData from "api/userData";

import api from "./ApiInstance";

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const navigate = useNavigate();
  const {auth, setAuth} = useContext(AuthProvider) 
  const {setPortfolio, setEducation, setProjects, setEmployment, setSkills} = useContext(PortfolioContext)
  const [loading, setLoading] = useState({
    state: '',
    msg: ''
  })   
  const [appMsg, setAppMsg] = useState({})
  
  const onPageLoad = async() => {
    setLoading({ state: true})    
    await authApi.checkLogin().then((res) => {
      if (res.status === 'success') {
        const {email, id, name, roles, Instructions} = res.user;
        setAuth({email, id, name, roles, Instructions})
        const location = window.location.pathname
        navigate(location === '/' ? '/setup/portfolios' : location)      
      } else {
        setAuth(false)
        setLoading(false)
      }
    })
  }
  
  const fetchUserData = async() => {
    await userData.getAll().then((res) => {
      if (res.status === 'success') {        
        setPortfolio(res.data.portfolio)
        setEducation(res.data.edu)
        setProjects(res.data.project)
        setEmployment(res.data.employment)
        setSkills(res.data.skill)
        
        setLoading(false)           
      }
    })
    
  }
  useEffect(() => { 
    onPageLoad();    
  }, [])  
  
  useEffect(() => {
    let run = true
    if (auth.id && run) {
      fetchUserData()
      return () => {
      run = false
      }
    }
    // eslint-disable-next-line
  }, [auth.id]) 
  
  useEffect(() => {
    setAppMsg({
      status: true,
      msg: api
    })
  }, [api])

  return (
    <AppContext.Provider value={{    
     loading, setLoading,
      appMsg, setAppMsg,

    }}>
      {children}
    </AppContext.Provider>
  )
}

export const useGlobalContext = () => {
  return useContext(AppContext)
}

export default AppContext