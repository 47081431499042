import React, {useState} from "react";

import skillApi from "api/skillApi";
import Input from "components/Forms/Input";


const AddSkill = (props) => {  
  const {setNewSkill, getUserSkills} = props
  const [formData, setFormData] = useState({})

  const handleFormData = (e) => {
    setFormData({
      ...formData,
      [e.target.id] : e.target.value
    })  
  }

  const handleSaveSkill = async (e) => {
    e.preventDefault()
    await skillApi.saveNewSkill(formData).then((res) => {
      if (res.status === 'success') {
        getUserSkills()
        setNewSkill(false)
      }
    })
  }


  return (
    <form className="form skill">
      <h1>New Skill</h1>
      
      <div className="form--content">
              <div className="fields">

              <Input label={'Skill Name'} type={'text'} name={'skill'} id={'skill'} placeholder={'Clicking stuff...'} formData={handleFormData}/>
              <br/>
              <Input label={'Skill Mastery'} type={'range'} name={'skill'} id={'skillMastery'} min={0} max={100} defaultValue={'0'} step={10} formData={handleFormData}/>

              <div className="btnGroup btnGroup-flex center">
                <button className="btn action" onClick={(e)=> handleSaveSkill(e)}>Save</button>
                <button className="btn warning" onClick={()=>setNewSkill(false)}>Cancel</button>
              </div>
            
              </div>
            </div>

    </form>

  )
}

export default AddSkill