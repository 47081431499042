import React, {useState} from "react";
import {ReactComponent as Chevron} from  'icons/fa-chevron-right.svg';
import InputField from "components/Forms/Input";

const BasicInfo = ({props}) => {
  const {onChange, formState} = props
  const [open, setOpen] = useState(true)
  
  return (
    <section className={`edit-portfolio ${open ? 'open' : ''}`}>

        <h3 onClick={()=> {setOpen(!open)}}><Chevron className={open ? 'open' : ''} /> Basic Information</h3>
        {<div className="fields container">  

          <InputField id={'name'} label={'Full Name'} span={'Your name as it will appear on your portfolio.'} type={'text'} value={formState.name} placeholder={'Your name'} formData={onChange} /> 

          <InputField id={'email'} label={'Your email'} span={'The one employers should use to contact.'} type={'email'} value={formState.email} placeholder={'hello@example.com'} formData={onChange} />

          <InputField id={'state'} label={'State you live in'} type={'text'} value={formState.state} placeholder={'FL'} formData={onChange}/>               

          <InputField id={'country'} label={'Country'} type={'text'} value={formState.country} placeholder={'USA'} formData={onChange} />         

        </div>}
    </section>

  )

}

export default BasicInfo