import React, {useContext} from "react";
import authApi from "api/authApi";
import { getToken } from "firebase/messaging";
import { messaging } from "PushNotifications/firebaseConfig";
import AuthContext from "context/AuthProvider";

const PNPermissionButton = () => {
  const {auth} = useContext(AuthContext) 

  const getPermission =  () => {
    Notification.requestPermission().then(async (permission) => {
      if (permission === 'granted') {
        const token = await getToken(messaging, {
          vapidKey: 'BHb60rI2VL6HoDNErQMxuAPZsx40gZmXBEEBymzsjR1SxgYLaQ3zqNSRO6Np4gbUugqi266l2Ave9jhGjrDqW5s',
        })
        if (token) {
          const data = {
            pushToken: token
          }
          authApi.updateMe(auth.id, data).then((res) => {
            console.log(res)
            console.log(`Notification permission granted`);            
          })
        }
        
      }      
    })       
  }  

  return (
    <button className="btn" onClick={getPermission}>Enable Push Notifications</button>
  )
}

export default PNPermissionButton