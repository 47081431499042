import React, {useState, useContext, useEffect} from "react";
import api from "context/ApiInstance";

// App Context
import AppContext from "context/Appcontext";
import PortfolioContext from "context/PortfolioProvider";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImages } from "@fortawesome/free-solid-svg-icons";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { faCamera } from "@fortawesome/free-solid-svg-icons";

import './imgUpload.scss'


const ImgUpload = (props) => {
  const [editingPortfolio] = useState(props.editingPortfolio)
  const [userMedia, setUserMedia] = useState(false)  
  const [imageUploaded, setImageUploaded] = useState(false)
  const {setAppMsg} = useContext(AppContext);
  const {getUsersPortfolios} = useContext(PortfolioContext)  
  
  // Gets the image and previews the image
  const [image, setImage] = useState();
  const [imgPreview, setImagePreview] = useState(); 
  // End

  const onImageChange = (e) => {
    setImage(e.target.files)
    setImagePreview(URL.createObjectURL(e.target.files[0]))
    setImageUploaded(true)    
  }

  const uploadImage = async (e) => {
    e.preventDefault();
    setAppMsg({state: true, class:'checking', msg: 'Uploading new photo.'})            
      await api({
        method: 'PATCH',
        url: `/api/v1/myportfolio/uploadphoto/${editingPortfolio.id}`,
        data : {portfolioPhoto: image[0]},
        headers: {
          "Content-Type" : "multipart/form-data"
        }
      }).then((res) => {                
        if (res.status === 'success') {    
          setImage()                    
          getUsersPortfolios();
          setImageUploaded(false)
          setAppMsg({state: true, class:'success', msg: 'success'})
        }
      }).catch((err)=> {
        //setAppMsg({state: true, class:'error', msg: err})
      })
    return false
  }
  
  const ImageBlurbStyles = {
    backgroundImage: `url(${imgPreview})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: '100%',
    width: '100%'
  };

  useEffect(() => {
    navigator.mediaDevices.getUserMedia({video: true}).then((res) => {      
       setUserMedia(true)
    }).catch((err) => { setUserMedia(false)} )
  }, [])

  return(
    <>
      <div className="imgUpload">
        <div className="imgUpload--container">
        <label htmlFor={editingPortfolio.id} title="Select a new Image"><FontAwesomeIcon icon={faImages} /></label>
        <input type="file" name="portfolioPhoto" id={editingPortfolio.id} accept="image/*" onChange={(e) => onImageChange(e)} />
        {!imgPreview && <img className="imgPreview" src={`/img/portfolio/${editingPortfolio.portfolioPhoto}`} onError={(e)=>e.currentTarget.src = '/img/portfolio/default.jpg'} alt='portfolio mug shot' /> }
        {imgPreview && <div style={ImageBlurbStyles}></div>}
      </div>   
      {userMedia && 
          <div className="camera">
            <div className="camera-container">
              <label htmlFor={`${editingPortfolio.id}_camera`} title="Take a Photo">Take a photo.</label>
              <input type="file" id={`${editingPortfolio.id}_camera`} accept="image/*" onChange={(e) => onImageChange(e)} capture="camera" />      
            </div>
          </div>             
      }
        {imageUploaded && <button style={{margin: '1rem'}} className="btn" title="Upload Image" onClick={uploadImage}><FontAwesomeIcon icon={faUpload}/> Upload</button>}
      <div className="btnGroup btnGroup-flex center">
      </div>
    </div>
    </>
  )

}

export default ImgUpload