import api from "context/ApiInstance";

class AdminRoles {
  // User model and auth
  async getAllUsers () {
    const response = await api.get('/api/v1/auth');
    return response
  }

  async deleteUser (id) {
    const response = await api.delete(`/api/v1/auth/${id}`)
    return response
  }
  // Portfolios
  async getAllPortfiolios () {
    const response = await api.get('/api/v1/portfolio')
    return response
  }
  
}


export default new AdminRoles();

