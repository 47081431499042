/* 

Originally I started this out thinking it was best to use Routes.... 
That is the reason for the path key. 

 sub: [
        {
          module: '' ,
          path: ''
        }
      ]
*/
const EditPortfolioLink = [
  {
    link: 'basic',
    name: 'Basic Info'
  },
  {
    link: 'about',
    name: 'About me'
  },
  {
    link: 'links',
    name: 'Links'
  },
  {
    link: 'skills',
    name: 'Skills'
  },
  {
    link: 'edu',
    name: 'Education'
  },
  {
    link: 'employment',
    name: 'Employment'
  },
  {
    link: 'projects',
    name: 'Projects'
  }
]


module.exports = {
  EditPortfolioLink
}