import React, {useContext} from 'react';
import {BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';

import AppContext from '../context/Appcontext';
import AuthContext from '../context/AuthProvider';


import Loading from '../components/Load/Loading'
import Login from './login';
import Header from 'layout/Header/Header';
import Navigation from 'components/Navigation/Navigation';

import Instructions from './instructions';
import AddPortfolios from './addPortfolios/Portfolios';
import AddProject from 'pages/addProjects';
import MyPortfio from 'pages/myPortfolio/index';
//admin menu
import Users from 'pages/adminMenu/users/';
import Education from './addEducation';
import Employment from './addEmployment';
import Skills from './addSkill';
import UserProjects from 'pages/adminMenu/projects/';
import UserPortfolios from  'pages/allPortfolios'
import MyAccount from './myAccount';

import AppMsgModule from '../components/AppMsgModule/AppmsgModule';

import background from 'images/bg-img.png'

import 'scss/index.scss'


const Admin = () => {    
  const {loading} = useContext(AppContext);    
  const { auth } = useContext(AuthContext);  

  const modulesBg = {
    backgroundImage: `linear-gradient(rgba(255,255,255,1), rgba(255,255,255,0.6)),url(${background})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    maxHeight: '100vh',
    overflowX: 'auto'
  }

  if (loading.state) return <Loading />
  if (!auth) return <Login /> 
  return (
    <main>
      <Navigation page={'setup'} />
      <div className="wrapper" style={modulesBg}>
        <section className="content">          
          <Header />                  
          <section id='modules'>  
          <Routes>
            <Route path="/" element={<Instructions />} />
            <Route path="education" element={<Education />} />
            <Route path="employment" element={<Employment />} />
            <Route path="skills" element={<Skills />} />
            <Route path="projects/*" element={<AddProject />} />              
            <Route path="portfolios/*" element={<AddPortfolios />} />           
            <Route path="myportolio" element={<MyPortfio />} />
            <Route path='myaccount' element={<MyAccount />} />
            <Route path='instructions' element={<Instructions />} />
            {auth.roles === 'admin' && <>
              <Route path="allusers" element={<Users />} />
              <Route path="allportfolios" element={<UserPortfolios />}/>
              <Route path="allresumes" />
              <Route path="allproject" element={<UserProjects />}/>
            </>}         

          
          </Routes>            
          </section>
        </section>              
      <AppMsgModule />
      </div>      
    </main>
  )
}

export default Admin;
