import api from "context/ApiInstance";

class Portfolio {  
  getUsersPortfolios() {
    return api.get('/api/v1/myportfolio')
  }
  getPublishedPortfolio(id) {
    return api.get(`/api/v1/myportfolio/published/${id}`)
  }
  newPortfolio(portfolio) {
    return api.post(`/api/v1/myportfolio`, portfolio)
  }
  updatePortfolio(id, data) {
    return api.patch(`/api/v1/myportfolio/${id}`, data)
  }
  deletePortfolio(id, portfolio) {
    return api.delete(`/api/v1/myportfolio/${id}`, {data: portfolio})
  }
  setActive(id, portfolio) {
    return api.patch(`/api/v1/myportfolio/${id}`, portfolio)
  }
  findActive(boolean) {
    // No specific route for this
  }
}


export default new Portfolio();

