import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";



const About = (props) => {
  const {portfolio} = props 
  
  useEffect(() => {
    Aos.init()    
  },[])
  
  const overFlow = {overflow: 'hidden'}

  return (    
      <div className="module" id="about" style={overFlow}>
        <div className="content">
        <div className="section-title" data-aos="fade-down" data-aos-duration="1000">
          <FontAwesomeIcon icon={faUser} className="titleIcon"/>
          <h2>About</h2>
        </div>
        
        <div className="row" data-aos="fade-up" data-aos-duration="1000">
          <p>{portfolio.blurb}</p>
        </div>

        <div className="row">
          <div className="col-lg-4" data-aos="fade-right" data-aos-duration="1000">
            <img src={`/img/portfolio/${portfolio.portfolioPhoto}`} onError={(e)=>e.currentTarget.src = '/img/portfolio/default.jpg'} className="img-fluid" alt="" />
          </div>

          <div className="col-lg-8 pt-4 pt-lg-0" data-aos="fade-left" data-aos-duration="1000">
            <h3>{portfolio.title}.</h3>
            <p className="fst-italic pt-4">
              {portfolio.blurbTwo}
            </p>

            <div className="row">
              <div className="col-lg-6">
                <ul>                  
                  {portfolio.website && <li><i className="bi bi-chevron-right"></i> <strong>Website:&nbsp;</strong>
                    <Link to={portfolio.website} target="_blank"><span>{portfolio.website}</span></Link></li>}
                  <li><i className="bi bi-chevron-right"></i> <strong>Email:&nbsp;</strong><Link onClick={(e) => {window.location = `mailto:${portfolio.email}`}}>{portfolio.email}</Link></li>
                  <li><i className="bi bi-chevron-right"></i> <strong>Location:</strong> <span>{portfolio.state}, {portfolio.country}</span></li>
                </ul>
              </div>
              <div className="col-lg-6">
                <ul>                  
                  {portfolio.degree && <li><i className="bi bi-chevron-right"></i> <strong>Degree:</strong> <span>{portfolio.degree}</span></li>}
                  {portfolio.status && <li><i className="bi bi-chevron-right"></i> <strong>Status:</strong> <span>{portfolio.status}</span></li>}
                </ul>
              </div>
            </div>
            <p>{portfolio.aboutme}</p>
          </div>
        </div>
        </div>
      </div>  
  )
}

export default About