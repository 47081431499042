import React, {useEffect, useState, useContext}  from 'react';

import AppContext from 'context/Appcontext';
import projectsApi from 'api/projectsApi';

import InputText from 'components/Forms/Input';
import TextArea from 'components/Forms/TextArea';

const AddProject = (props) => {
  const {setAddProject, getMyProjects, project, editing} = props;
  const {setAppMsg} = useContext(AppContext)  
  const [formData, setFormData] = useState({    
    skillsUsed: []
  });
  const [newSkill, setNewSkill] = useState('');
  const onChangeHandler = (e) => {   
    setFormData({
      ...formData,            
      [e.target.id]: e.target.value
    })            
  }

  const getSkill = (e) => {    
    setNewSkill(e.target.value)  
  }
  
  const addSkillToFormData = () => {    
    if (!formData.skillsUsed.includes(newSkill) || !newSkill) {
      formData.skillsUsed.push(newSkill)            
      formData.skillsUsed.sort((a, b) => a.value - b.value)
    } else {
      setAppMsg({state: true, class: "error", msg: 'Skill already declared.'
      })
    }
    setNewSkill('')        
  }

  const deleteSkill = (e) => {
    const newList = formData.skillsUsed.filter((skill) => {
      return skill !== e.target.id 
    })            
    setFormData({
      ...formData,
      skillsUsed: newList
    })
  } 

  const saveNewProject = async (e, id) => {
    e.preventDefault()
    if (!editing) {
      await projectsApi.saveMyProject(formData).then(res => {        
        if (res.status === 'success') {
          getMyProjects()
          setAddProject()        
        }
      }).catch(err => {
        
      })
    } else if (editing) {
      await projectsApi.updateMyProject(id, formData)        
        getMyProjects()
        setAddProject()                
    }
    
  }

  useEffect(() => {
    if (project) {
      setFormData(project)
    }    
  // eslint-disable-next-line
  }, [])


  const saveButton = formData.projectName && formData.projectLink && formData.projectThumbnailUrl && formData.projectCategory && formData.skillsUsed.length;

  
  return (   
      <form className="form project">
        {!editing && <h1>Project Details</h1>}
        {editing && <h1>Editing {formData.projectName}</h1>}
        <div className="form--content">
          <div className="fields">
    
            {formData.projectThumbnailUrl && <img src={formData.projectThumbnailUrl} alt={formData.projectName}/>}
            <InputText label="Project Name" type="text" id="projectName" value={formData.projectName} placeholder={'Enter your projects name'} formData={onChangeHandler} required/>
            <InputText label="Project Link" type="url" id="projectLink" value={formData.projectLink} placeholder={'https://liveurl.com'} formData={onChangeHandler} required/>
            <InputText label="Project Thumbnail" type="url" id="projectThumbnailUrl" value={formData.projectThumbnailUrl} placeholder={'https://liveimage.com'} formData={onChangeHandler} required/>
            <InputText label="Project Category" type="text" id="projectCategory" value={formData.projectCategory} placeholder={'Website, blog, art...'} formData={onChangeHandler} required/>      
            <InputText label="Skills" type="text" id="skillsUsed" placeholder={'Add a skill'} value={newSkill} formData={getSkill} /> 
            {newSkill && <button className='btn' onClick={() => {addSkillToFormData()}}>Confirm Skill</button>}

            <TextArea label={'Details'} id={'details'} value={formData.details} formData={onChangeHandler}/>

                
            {formData.skillsUsed?.length > 0 && <ul className='skill'> {formData.skillsUsed.map((skill, index) => {
              return (<li key={index} id={skill} onClick={(e)=>deleteSkill(e)} title="Delete skill">{skill} X</li>)
            })} </ul>}     
          </div>								
          <div className="btnGroup btnGroup-flex center">
            <button className="btn" onClick={() => {setAddProject()}}>Cancel</button>
            {saveButton > 0 && <button className="btn" onClick={(e) => saveNewProject(e, formData.id)}>Save</button>}
          </div>
        </div>
      </form>    
  )
}

export default AddProject