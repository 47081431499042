import React, {useEffect} from 'react';
import {BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';

import { toast, ToastContainer } from "react-toastify";
import Message from 'components/Notifications/Message';
import "react-toastify/dist/ReactToastify.css";
import { getMessaging, onMessage } from 'firebase/messaging';

import MyPortfolio from './pages/myPortfolio/index';
import Login from 'pages/login';
import PasswordReset from 'pages/passwordReset/PasswordReset';
import Admin from 'pages/Admin';

function App() { 
  const messaging = getMessaging();
  onMessage(messaging, (payload) => {
    console.log(payload)
    toast(<Message notification={payload.notification} />);
  });

  return (
    <>
    <Routes>            
      <Route path="/" element={<MyPortfolio />} />                  
      <Route path="/myportfolio/:id" element={<MyPortfolio />} />            
      <Route path="/previewportfolio/*" element={<MyPortfolio />} />
      <Route path="/setup/*" element={<Admin />} />                  
      <Route path="/login" element={<Login />} />      
      <Route path="/resetPassword/:token" element={<PasswordReset />} />    
      <Route path="*" element={<Login />} />      
    </Routes>
    <ToastContainer />
    </>
  
  );
}

export default App;
