import React, { useState, useEffect, useContext } from "react";

import PortfolioContext from "context/PortfolioProvider";
import api from 'api/educationApi'

import Card from "components/Card/Card";
import Modal from "components/Modal/Modal";
import ConfirmDelete from "components/ConfirmDelete/ConfirmDelete";

import Edu from "./Edu";


import {ReactComponent as TitleIcon} from 'icons/fa-user-graduate.svg';

const Education = () => {
  const [addEdu, setAddEdu] = useState(false)  
  const [toEdit, setToEdit] = useState(false)
  const {education, getUserEducation} = useContext(PortfolioContext)


  const handleAddEdu = (edu) => {
    setAddEdu(!addEdu)      
  }

  const handleEditEdu = (edu) => {
    setAddEdu(!addEdu)  
    setToEdit(edu)      
  }

  const handleDeleteEdu = async (id) => {    
    await api.deleteEducation(id).then(getUserEducation())
  }  

  useEffect(() => {
    if (!education) {      
      getUserEducation()              
    }
  }, [education])

  const converDate = (date) => {
    const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"];
    let month = new Date(date).getMonth();
    let year = new Date(date).getFullYear() 
    if (month < 0 || month == 11) {
      month = 0
      year++
    } else {
      month++
    }
    
    return `${monthNames[month]}, ${year}`
  }

  return (
    <div className="module">
      <div className="module--title">
        <TitleIcon className="titleIcon"/>
        <h4>My Education</h4>
      </div>

      {education && education.map((ed, index) => {
				return <Card id={ed.id} key={ed.id}>											
							<div className="information edu_width">
								<h4>{ed.SchoolName}</h4>
                <span>{ed.Location}</span>																
							</div>
              <div className="information">
                <h4>{ed.Field}</h4>
                {/* <span>{ed.Degree}, {new Date(ed.GraduationYear).toLocaleString()}</span> */}
                <span>{ed.Degree}, {converDate(ed.GraduationYear)}</span>
              </div>
							<div className="btnGroup btnGroup-flex">
								<button className="btn warning" onClick={()=>handleEditEdu(ed)}>Edit</button>
                <ConfirmDelete item={ed} delFunction={handleDeleteEdu}/>
              </div>						
					</Card>					
				
			})
			}


      {!addEdu && <button className="btn btn-sticky--bottom" onClick={handleAddEdu}>+ New Education</button>
      }

      {addEdu && <Modal>
        <Edu getUserEducation={getUserEducation} handleAddEdu={handleAddEdu} editing={toEdit} setEditing={setToEdit}/>
      </Modal>}
    </div>
  )
}

export default Education