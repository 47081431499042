import api from "context/ApiInstance";

class Education {  
  getUserEducation() {
    return api.get('/api/v1/edu')
  }
  newUserEducation(edu) {
    return api.post(`/api/v1/edu`, edu)
  }
  updateEducation(id, edu) {
    return api.patch(`/api/v1/edu/${id}`, edu)
  }
  deleteEducation(id, edu) {
    return api.delete(`/api/v1/edu/${id}`, {data: edu})
  }
}


export default new Education();

