import React, {useContext, useState} from "react";
import { NavLink } from 'react-router-dom';

import AuthContext from "context/AuthProvider";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGraduationCap } from "@fortawesome/free-solid-svg-icons";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";
import { faCode } from "@fortawesome/free-solid-svg-icons";
import { faDiagramProject } from "@fortawesome/free-solid-svg-icons";
import { faUserTie } from "@fortawesome/free-solid-svg-icons";

import { faUser } from "@fortawesome/free-solid-svg-icons";



import './NavigationLinks.scss'



const NavigationElement = (props) => {
  const {auth} = useContext(AuthContext)
  const admin = auth.roles === 'admin' || false;
  const {navhandler} = props
  const [showSubMenu, setShowSubMenu] = useState(null) 

  const NavigationConfig = [  
    {
      module: 'Education',
      path: 'education',
      icon : <FontAwesomeIcon icon={faGraduationCap} />    
    },
    {
      module: 'Employment',
      path: 'employment',
      icon: <FontAwesomeIcon icon={faBriefcase} />    
    },
    {
      module: 'Skills',
      path: 'skills',
      icon: <FontAwesomeIcon icon={faCode} />    
    },
    {
      module: 'projects',
      path: 'projects',
      icon : <FontAwesomeIcon icon={faDiagramProject} />    
    },
    {
      module: 'Portfolios',
      path: 'portfolios',
      icon: <FontAwesomeIcon icon={faUserTie} />
    }
  ]

  const AdminLinks = [
    {
      module: 'Users',
      path: 'allusers',
      icon: <FontAwesomeIcon icon={faUser} />
    },
    {
      module: 'Education',
      path: 'alleducations',
      icon: <FontAwesomeIcon icon={faGraduationCap} />
    },
    {
      module: 'Employment',
      path: 'allemployments',
      icon: <FontAwesomeIcon icon={faBriefcase} />
    },
    {
      module: 'Projects',
      path: 'allprojects',
      icon: <FontAwesomeIcon icon={faDiagramProject} />
    },
    {
      module: 'Porfolios',
      path: 'allportfolios',
      icon: <FontAwesomeIcon icon={faUserTie} />
    }
  ]
  
  

  return (
    <>    
      <ul className="mega-nav--links with-title">
      <h4>Portfolio Setup</h4> 
      
        {NavigationConfig.map((link, index) => {
          if (link.sub) {            
            return (
              <span key={index}>
                <li onClick={navhandler}>
                  <NavLink to={link.path}  className={({ isActive }) => isActive ? "mega-nav--links-title active" : "mega-nav--links-title"}>
                  <span></span>
                    {link.module}
                  </NavLink>
                </li>

                <ul className={`submenu ${showSubMenu === index ? 'show' : 'hidden'}`}>
                  {link.sub.map((sublink, subIndex) => {
                    return <li onClick={navhandler} key={subIndex} className={`mega-nav--module-links`}>
                      <NavLink to={sublink.path}>
                        {sublink.module}
                      </NavLink></li>
                  })}
                </ul>
              </span>
            )
          } else {
            return <li key={index} onClick={navhandler} >
              <NavLink to={link.path}  className={({ isActive }) => isActive ? "mega-nav--links-title active" : "mega-nav--links-title"}>              
              <span>{link.icon}</span>
                {link.module}
              </NavLink>
            </li>          
          }
        })}
      </ul>

      {admin && 
      <ul className="mega-nav--links with-title">
        <h4>Admin Menu</h4>            
        {AdminLinks.map((link, admin) => {        
            return <li key={admin} >
            <NavLink to={link.path} onClick={navhandler} className={`mega-nav--links-title`}>
            <span>{link.icon}</span>
              {link.module}
            </NavLink>
          </li>                  
        })}
      </ul>
      }
      </>
  )
}

export default NavigationElement