import React, {useEffect, useState} from "react";

import Card from "components/Card/Card";

import adminRole from "api/adminRole";
import Modal from "components/Modal/Modal";

const Users = () => {
  const [allUsers, setAllUsers] = useState(false)  
  const [delUser, setDelUser] = useState(false)

  const handleGetAllUsers = async () => {
    await adminRole.getAllUsers()
    .then((res) => {    
      setAllUsers(res.data)
    })

  }

  const DeleteUser = (user) => {
    setDelUser(user)
  }
  
  const confirmDeleteUser = async (user) => {
    const {id} = delUser
    await adminRole.deleteUser(id)  
    setDelUser(false)   
    handleGetAllUsers()
  }

  useEffect(() => {
    handleGetAllUsers();
  }, [])

  return (
    <>
    <div className="btnGroup btnGroup-flex">
      {/* <button className="btn">Create a new user</button>     */}
      <button className="btn">Find a user</button>  
    </div> <br />
      {allUsers && <>
        {allUsers.map((user) => {
          return <Card key={user.id} classes={'align-left'}>
            <b>{user.name}</b>, {user.email}, {user.roles === 'admin' ? user.roles.toUpperCase() : ''}
            <div className="btnGroup btnGroup-flex">                        
              <button className="btn">Send Password Reset</button>
              {user.roles !== 'admin' && <button className='btn' onClick={() => DeleteUser(user)}>Delete User</button>}                            
            </div>
          </Card>
        })}
      <button className="btn" onClick={() => setAllUsers(false)}>Clear all users</button>
      </>}


      {delUser && <Modal><Card>

        <div className="content" style={{textAlign:'center'}}>
        <h5>Delete {delUser.name}?</h5>
        <div className="btnGroup btnGroup-flex">
          <button className="btn danger" onClick={()=>confirmDeleteUser()}> Delete User</button>
          <button className="btn" onClick={()=>setDelUser(false)}>Cancel</button>
        </div>
        </div>
      </Card>
      </Modal>}

    </>
  )
}

export default Users