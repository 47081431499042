import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIdBadge } from "@fortawesome/free-solid-svg-icons";

const converDate = (date) => {
  const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"];
  let month = new Date(date).getMonth();
  let year = new Date(date).getFullYear() 
  if (month < 0 || month === 11) {
    month = 0
    year++
  } else {
    month++
  }
  
  return `${monthNames[month]}, ${year}`
}



const Resume = (props) => {
  const {portfolio} = props

  return (
  <div className="module" id="resume">
    <div className="section-title" data-aos="fade-down" data-aos-duration="1000">
      <FontAwesomeIcon icon={faIdBadge} className="titleIcon" />
      <h2>Resume</h2>
    </div> 

    <div className="container" data-aos="fade-up" data-aos-duration="1000">
      <h4>{portfolio.name}</h4> 
      <span>{portfolio.email}</span><br/>
      <span>{portfolio.state}, {portfolio.country}</span>      
    </div>   

    
    <div className="row" data-aos="fade-up" data-aos-duration="1000">
      <div className="col-lg-12">
        <p><em>{portfolio.intro}</em></p>
      </div>
    </div>

    

      <div className="pb-0"  data-aos="fade-up" data-aos-duration="1000">
        <ul>
          <li></li>              
        </ul>
      </div>

      <div className="row">
        <div className="col-lg-6" data-aos="fade-up" data-aos-duration="1000">          

          <h3 className="resume-title">Education</h3>
            {portfolio.education.map((edu, index) => {
              return (              
                <div className="resume-item" key={index}>
                <h4>{edu.Field}</h4>
                <h5>{edu.Degree}: {converDate(edu.GraduationYear)}</h5>
                <p><em>{edu.SchoolName}, {edu.Location}</em></p>
                <p style={{paddingLeft: '1rem'}}>{edu.details}</p>
              </div>              
              )
            })}
        </div>
        <div className="col-lg-6" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
          <h3 className="resume-title">Professional Experience</h3>
          {portfolio.employment.map((job, index) => {
            return (
            <div className="resume-item" key={index}>
              <h4>{job.JobTitle}</h4>
              <h5>{converDate(job.StartDate)} - {job.StillEmployed ? 'Current' : converDate(job.EndDate)}</h5>
              <p><em>{job.CompanyName}, {job.Location}</em></p>              
              <p style={{paddingLeft: '1rem'}}>{job.details}</p>              
            </div>
            )
          })}
        </div>
      </div>

      <div className="btn" onClick={()=>window.print()}>Print</div>
    </div>      
  )
}

export default Resume