import React from "react";
import Logo from '../../images/logo512.png'

import AppMsg from '../AppMsgModule/AppmsgModule'

import './Loading.scss'

const Loading = () => {
  return (
    <div className="main loading" style={{backgroundImage: "url(/login-bg.png)"}}>
      <div className="loading-container">
        <div className="loading-logo">
          <img src={Logo} alt="My Portfolio Logo"/>

        </div>
        <h3>Loading</h3>        
      <AppMsg />
      </div>
    </div>
  )
}

export default Loading