import api from "context/ApiInstance";

class Projects {
    getMyProjects() {
        return api.get('/api/v1/myProjects')
    }
    saveMyProject(formData) {
        return api.post('/api/v1/myProjects', formData)
    }
    delteMyProject(id) {               
        return api.delete(`/api/v1/myProjects/${id}`)
    }
    updateMyProject(id, formData) {
        return api.patch(`/api/v1/myProjects/${id}`, formData)
    }
}

export default new Projects();