import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation} from "react-router-dom";

import { EditPortfolioLink } from "layout/SideNavigation/NavigationLinks/SetupLinksConfig";
import PortfolioProvider from "context/PortfolioProvider";
import portfolioApi from "api/portfolioApi";
import Modal from "components/Modal/Modal";

//Sections
import BasicInfo from "./basicInfo";
import AboutInfo from "./aboutInfo";
import LinksInfo from "./linksInfo";
import Skills from "./skill";
import Projects from "./ProjectsLink";
import Edu from './eduInfo';
import Employment from "./employmentInfo";


const PortfolioEdit = (props) => {  
  const navigate = useNavigate()
  const {getUsersPortfolios} = useContext(PortfolioProvider)
  const [formState, setFormState] = useState(false);    
  const {editingPortfolio, handleEditing} = props
  const [tab, setTab] = useState('basic')

  
  const updatePortfolio = async (data) => {
    await portfolioApi.updatePortfolio(editingPortfolio.id, data)
    .then(res=> {      
      if (res.status === 'success') {
        getUsersPortfolios()        
        setTimeout(() => {
          handleEditing(false)
        }, 1000)        
        
      }
    })
  }
  
  const onChange = (e, type) => { 
    if (e === 'projects' || e === 'education' || e === 'employment' || e === 'skills') {
      setFormState({
        ...formState,
        [e]: type 
      })
    } else {
      setFormState({    
        ...formState,      
        [e.target.id]: e.target.value
      })    
    }    
  }
  
  const onSubmit = (e, data) => {    
    e.preventDefault() 
    updatePortfolio(data);
  }  

  useEffect(() => {
    setFormState(editingPortfolio)
  },[editingPortfolio])

  const buttonGroupMod = {
    maxWidth: '100%',
    width: '100%',
    flexWrap: 'wrap',
    margin: '0',
    position: 'sticky',
    top: '0',
    zIndex: '9999',
    backgroundColor: '#fff'
  }

  const button = {
    margin: '0',
    borderRadius: '0',
    flex: '1 1 auto'    
  }
  
  
  if (!editingPortfolio) return navigate('./portfolios') 
  
  return (
    
    

        <Modal>
        <form className="form edit" encType="multipart/form-data" onSubmit={(e) => e.preventDefault()}>
          <h1>{editingPortfolio.title ? `Editing ${editingPortfolio.title}`: 'New Portfolio'}</h1>
          <div className="btnGroup btnGroup-flex " style={buttonGroupMod}>
            {EditPortfolioLink.map((btn, index) => {
              return (
                <button key={index} onClick={()=>setTab(btn.link)} className={`btn ${tab === btn.link ? 'active' : ''}`} style={button}>{btn.name}</button>
              )
            })}
            
            {formState !== editingPortfolio && <button className="btn action" onClick={(e) => onSubmit(e, formState)} style={button}> submit </button>}
            <button className="btn warning" onClick={()=>handleEditing(false)} style={button}> back </button>            

          </div>
          <div className="form--content">
            <div className="fields">
            
              {tab === 'basic' && <BasicInfo props={{onChange, formState}} />}
              {tab === 'about' && <AboutInfo props={{onChange, formState}} />}
              {tab === 'links' && <LinksInfo props={{onChange, formState}} />}
              {tab === 'edu' && <Edu props={{onChange, editingPortfolio}} />}
              {tab === 'employment' && <Employment props={{onChange, editingPortfolio}} />}
              {tab === 'projects' && <Projects props={{onChange, editingPortfolio}} />}
              {tab === 'skills' && <Skills props={{onChange, editingPortfolio}}/>}
              
            </div>

                      
          </div>
        </form>
        </Modal>    
    
  )
}

export default PortfolioEdit