import React from "react";
import { Link } from 'react-scroll'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faCode } from "@fortawesome/free-solid-svg-icons";
import { faIdBadge } from "@fortawesome/free-solid-svg-icons";
import { faDiagramProject } from "@fortawesome/free-solid-svg-icons";

const ScrollNavigation = ({...props}) => {
  const {portfolio, navhandler} = props



  const links = [
    {
      module: 'Home',
      path: 'hero',
      icon: <FontAwesomeIcon icon={faHome} />
    },
    {
      module: 'About',
      path: 'about',
      icon: <FontAwesomeIcon icon={faUser} />
    },
    {
      module: 'Resume',
      path: 'resume',
      icon: <FontAwesomeIcon icon={faIdBadge} />
    },
    {
      module: 'Skills',
      path: 'skills',
      icon: <FontAwesomeIcon icon={faCode} />
    },
    {
      module: 'Projects',
      path: 'projects',
      icon: <FontAwesomeIcon icon={faDiagramProject} />
    }  
  ]
  


  
  return (
    <ul className="mega-nav--links">
      {links.map((link, index) => {
        if ( (link.module === 'Resume' && !portfolio.education.length) 
            || (link.module === 'Resume' && !portfolio.employment.length) ) {return}
        if (link.module === 'Skills' && !portfolio.skills.length) {return}
        if (link.module === 'Projects' && !portfolio.projects.length ) {return}        
        return (          
            <li key={index}>
              <Link  onClick={navhandler} to={link.path} spy={true} smooth={true} offset={-10} duration={500} className="mega-nav--links-title" activeClass="active" >
                <span>{link.icon}</span>
                {link.module}
              </Link>
            </li>          
        )
      
      })}
    </ul>
  )
}

export default ScrollNavigation
