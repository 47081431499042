import React, {useContext} from "react";
import { NavLink } from "react-router-dom";

import AuthContext from "context/AuthProvider";
import authApi from "api/authApi";
import Modal from "components/Modal/Modal";

import {ReactComponent as AccountIcon} from 'icons/fa-id-card.svg';
import {ReactComponent as InstructionsIcon} from 'icons/fa-question.svg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"

import './UserMenu.scss'
import PortfolioContext from "context/PortfolioProvider";


const UserMenu = (props) => {
  const {handleUserMenu, navhandler} = props;
  const {setAppMsg, setAuth, auth} = useContext(AuthContext)
  const {setPortfolio, setEducation, setProjects, setEmployment, setSkills} = useContext(PortfolioContext)

  const reloadPage = () => {
    return window.location.reload(false);
  }


  //Log Out
  const logOut = async () => {          
    await authApi.logout()   
    .then(res => {              
      setAuth(false)
      setPortfolio(false)
      setEducation(false)
      setProjects(false)
      setEmployment(false)
      setSkills(false)                           
    }).catch (err => {            
      setAppMsg({
        status: true,
        class: 'error',
        msg: 'Something went wrong when logging out.'
      })
    })    
}   


  return (
    <Modal classes={'user_menu'} >
      <h2>{auth.name}</h2>
      <NavLink onClick={navhandler} className={'user_menu--link'} to={'../setup/myaccount'}><AccountIcon/>My Account</NavLink>               
      <NavLink onClick={navhandler} className={'user_menu--link'} to={'../setup/instructions'}><InstructionsIcon/>Instructions</NavLink>               
      <NavLink onClick={navhandler} className={'user_menu--link'} to={'../setup/portfolios'}><FontAwesomeIcon icon={faArrowLeft} />Back to setup</NavLink>               
      <div className="btnGroup">
        <button className="btn" onClick={handleUserMenu}>Close Menu</button>
        
      </div>
        <button className="btn" onClick={logOut}>Sign Out</button>                
    </Modal>
  )
}

export default UserMenu