import React, { useState, useContext, useRef, useEffect } from "react";

import  PortfolioProvider from "context/PortfolioProvider";

import Card from "components/Card/Card";
import Modal from "components/Modal/Modal";
import AddSkill from "./addSkill";
import skillApi from "api/skillApi";
import ConfirmDelete from "components/ConfirmDelete/ConfirmDelete";
import Input from "components/Forms/Input";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCode } from "@fortawesome/free-solid-svg-icons";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";


const Skills = () => {
  const {skills, getUserSkills} = useContext(PortfolioProvider);
  const [newSkill, setNewSkill] = useState(false)  
  const [formData, setFormData] = useState(false);
  const [edit, setEdit] = useState([])

  const handleFormData = (e) => {
    setFormData({
      ...formData,
      [e.target.id] : e.target.value
    })

  }

  const handleUpdateSkill = (skill) => {  
    //let skill = formData[index]
    let id = skill.id
    skillApi.updateSkill(id, formData).then((res) => {
      if (res.status === 'success') {
        getUserSkills()
        setEdit(false)
        setFormData(false)
      }
    })
  }

  const handleCancelEdit = () => {
    setEdit(false)
    setFormData(false)
  }

  const handleDeleteSkill = (id) => {
    skillApi.deleteSkill(id).then((res)=> {
      getUserSkills()
    })
  }

  const gridStyles = {
    width : '100%',
    maxWidth: '100%',
    display : 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(30rem, 1fr))',
    columnGap: '3rem'
  }

  const progressWidth = {
    width: '100%',
    maxWidth: '100%'
  }

	return (
		<div className={`module`}>
      <div className="module--title">
        <FontAwesomeIcon icon={faCode} className="titleIcon" />
        <h4>My Skills</h4>
      </div>
      {!!skills.length && 
      <Card>
        <div style={gridStyles}>
          {skills.map((skill, index) => {
            return <div key={index} style={{marginBottom: '1rem', width: '90%'}}>
              {edit !== skill.id &&<> <label htmlFor={skill.skill}>{skill.skill}&nbsp;<span style={{fontSize: '1.2rem'}}><i>{skill.skillMastery}%</i></span> </label> <br/></>}
              

              {edit !== skill.id && <progress style={progressWidth} value={skill.skillMastery} max={'100'}></progress>         }

              {edit === skill.id && <Input label={skill.skill} id={'skillMastery'} type={"range"}
              min={'0'} max={'100'} step={'10'} defaultValue={skill.skillMastery} formData={(e)=>handleFormData(e)}/>}

              <div className="btnGroup btnGroup-flex">    
                {/* {edit.includes(skill.id) && <div className="btn action" onClick={()=>updateSkill(index)}>update</div>} */}
                {edit === skill.id && <>
                  {formData && <button className="btn action" onClick={()=>handleUpdateSkill(skill)}>update</button>}
                  <ConfirmDelete item={skill} delFunction={handleDeleteSkill}/>
                  <button className="btn" onClick={()=>handleCancelEdit()}>cancel</button>
                </>}

                {edit !== skill.id && <button className="btn warning" onClick={()=>setEdit(skill.id)}>Edit</button>}
              </div>
            </div> 
          })}                   

        </div>
        
      </Card>}


      {newSkill && <Modal><AddSkill getUserSkills={getUserSkills} setNewSkill={setNewSkill} /></Modal>}

      {skills && !newSkill && <button className="btn btn-sticky--bottom" onClick={()=>setNewSkill(!newSkill)}>+ New Skill</button>}
      
      {/* {addSkill && <Modal>
          <AddSkill setAddSkill={setAddSkill} />
        </Modal>} */}
    </div>

			
	)
}

export default Skills

