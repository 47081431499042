import React, {useState, useContext, useEffect} from "react";
import { validate } from 'react-email-validator'

import AuthContext from "context/AuthProvider";
import AppContext from "context/Appcontext";
import InputField from "components/Forms/Input";
import authApi from "api/authApi";
import api from "api/userData";
import ConfirmDelete from "components/ConfirmDelete/ConfirmDelete";

const UserInfoChangeForm = () => {
  const {auth, setAuth} = useContext(AuthContext)
  const [formData, setFormData] = useState(false)  
  const {setAppMsg} = useContext(AppContext)  

  const handleFormData = (e) => {    
    setFormData({
      ...formData,
      [e.target.id] : e.target.value
    })    
  }

  const updateUserInfo = async (data) => {        
    if (!data.name || !data.email || !validate(data.email)) {
      let msg = data.name.length ? 'Missing email!' : 'Missing name!'
      setAppMsg({
        state: true,
        msg: msg,
        class: 'error'
      })
      return
    }
    const id = auth.id;    
    await authApi.updateMe(id, data).then((res) => {            
      setAuth(res.user)
      setFormData(res.user)
    })
  }

  const deleteMyAccount = async () => {
    await api.deleteUser().then((res)=> {
      window.location.reload()
    })
  }

  useEffect(() => {
    setFormData(auth)        
  }, [])
  
  
  return (
  <form className="form my-account" onSubmit={(e) => e.preventDefault()}>
    <div className="form--content">
      <h2>My Information</h2>
      <div className="fields">    
          <InputField id={'name'} type={'text'} name={'user-name'} suggested={'name'} placeholder={auth.name} value={auth.name} formData={handleFormData}/>
          <InputField id={'email'} type={'email'} name={'user-email'} suggested={'email'} placeholder={auth.email} value={auth.email} formData={handleFormData}/>       
          <div className="btnGroup btnGroup-flex center">
          {auth !== formData && 
            <button className="btn" onClick={() => {updateUserInfo(formData)}}>Update me</button>                 
          }
          <ConfirmDelete item={auth} delFunction={deleteMyAccount} text={'delete my account'}/>
          </div>
      </div>
    </div>
  </form>

  )
}


export default UserInfoChangeForm