import React, {useState, useEffect, useContext} from "react";

import PortfolioContext from "context/PortfolioProvider";

import api from 'api/employmentApi'

import InputText from "components/Forms/Input";
import TextArea from "components/Forms/TextArea";


const Job = (props) => {
  const {getUserEmployment} = useContext(PortfolioContext)
  const {handleAddJob, editing, setEditing} = props;
  const [formData, setFormData] = useState(false)
  const [checked, setChecked] = useState(editing.StillEmployed)

  const onChangeHandler = (e) => {    
    setFormData({
      ...formData,
      [e.target.id] : e.target.value
    })   
  }

  const handleSaveJob = async (e) => {
    e.preventDefault()
    await api.newUserEmployement(formData).then((res) => {    
      if (res.status === 'success') {
        getUserEmployment()    
        handleAddJob()
      }
    })   
  }
  
  const handleEdit = async (e) => {
    e.preventDefault()  
    const {id} = formData
    await api.updateEmployement(id, formData).then(res => {      
      if (res.status === 'success') {
        getUserEmployment()
        setEditing(false)
        handleAddJob()
      }
    })
  }

  const handleChecked = (e) => {
    setFormData({
      ...formData,
      [e.target.id] : e.target.checked
    })
    setChecked(e.target.checked)
  }

  const handleCancel = () => {
    setEditing(false)
    handleAddJob()
  }

  useEffect(() => {        
    if (editing) {
      setFormData(editing)
      setChecked(editing.StillEmployed)
    }    
  }, [editing])

  const saveButton = formData.CompanyName || formData.JobTitle;

  return (
    <form className="form job">
      {!editing && <h1>Employment Details</h1>}
      {editing && <h1>Editing {formData.CompanyName}</h1>}
      <div className="form--content">
        <div className="fields">

          <InputText label="Company Name" type="text" id="CompanyName"
          value={formData.CompanyName} placeholder={'Company Name'} formData={onChangeHandler}/>

          <InputText label="Position Title" type="text" id="JobTitle"
          value={formData.JobTitle} placeholder={'Director'} formData={onChangeHandler}/>

          <InputText label="Location" type="text" id="Location"
          value={formData.Location} placeholder={'1327 Los Angeles'} formData={onChangeHandler}/>

          <InputText label="Start Date" type="month" id="StartDate"
          value={formData.StartDate ? formData.StartDate : ''} formData={onChangeHandler}/>

          <InputText label="Still Employed" type="checkbox" id="StillEmployed" formData={(e) =>handleChecked(e)} checked={checked}/>

          {!checked && <InputText label="End Date" type="month" id="EndDate"
          value={formData.EndDate ? formData.EndDate :  ''} formData={onChangeHandler}/>}

          <TextArea label={'Details'} id={'details'} value={formData.details} formData={onChangeHandler}/>


        </div>
        <div className="btnGroup btnGroup-flex center">
            <button className="btn" onClick={handleCancel}>Cancel</button>
            {saveButton && <button className="btn" onClick={ editing ? (e) => {handleEdit(e)} : (e) => handleSaveJob(e)}>Save</button>}
          </div>

      </div>
    </form>
  )
}

export default Job