import React, { useEffect, useState } from "react";
import Modal from "components/Modal/Modal";
import Card from "components/Card/Card";


const ConfirmDelete = (props) => {
  const {item, delFunction, text} = props;
  const [toDel, setToDel] = useState(item)
  const [delConfirm, setDelConfirm] = useState(false)

  const handleShowDelete = () => {
    setDelConfirm(!delConfirm)
  }

  const deleteController = (a) => {
    delFunction(item.id)
    setDelConfirm(!delConfirm)
  }

  useEffect(() => {
    setToDel(item)
  }, [item])

  return (
    <>
      <button className="btn danger" onClick={handleShowDelete}>{text || 'delete'}</button>
      {delConfirm && <Modal classes={'del-component'}><Card>
        <div style={{textAlign: 'center', width: '100%'}}>
          <h4>Are you sure you want to delete {toDel.title || toDel.name || toDel.SchoolName || item.CompanyName || item.projectName || item.skill}</h4>
        </div>
        <div className="btnGroup btnGroup-flex center" style={{justifyContent: 'center'}}>
          <button className="btn danger" onClick={()=>deleteController(toDel.id)}>{text || 'Delete'}</button>
          <button className="btn" onClick={() => setDelConfirm(false)}>Cancel</button>
        </div>
      </Card>
      </Modal>}



    </>

  )
}

export default ConfirmDelete