import React from "react";
import { Link } from "react-router-dom";


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faEarthAmericas } from "@fortawesome/free-solid-svg-icons";


const SocialMediaLinks = (props) => {
  const {portfolio} = props
  return (
    <div className="social-links mt-3 text-center">
    {portfolio.linkedin && <Link to={portfolio.linkedin} target="_blank" className="linkedin"><FontAwesomeIcon icon={faLinkedin}/></Link>}      
    {portfolio.github && <Link to={portfolio.github} target="_blank" className="github"><FontAwesomeIcon icon={faGithub} /></Link>}            
    {portfolio.youtube && <Link to={portfolio.youtube} target="_blank" className=""><FontAwesomeIcon icon={faYoutube} /></Link>}         
    {portfolio.twitter && <Link to={portfolio.twitter} target="_blank" className="twitter"><FontAwesomeIcon icon={faTwitter}/> </Link>}                    
    {portfolio.facebook && <Link to={portfolio.facebook} target="_blank" className="facebook"><FontAwesomeIcon icon={faFacebook} /></Link>}
    {portfolio.instagram && <Link to={portfolio.instagram} target="_blank" className="instagram"><FontAwesomeIcon icon={faInstagram} /></Link>}   
    {portfolio.mywebsite && <Link to={portfolio.mywebsite} target="_blank" className="my-website"><FontAwesomeIcon icon={faEarthAmericas}/></Link>}      
  </div>

  )
}


export default SocialMediaLinks