import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import Card from "components/Card/Card";
import api from "api/userData";
import {ReactComponent as TitleIcon} from 'icons/fa-question.svg';

import './instructions.scss'
import AuthContext from "context/AuthProvider";


const Instructions = () => {
  const navigate = useNavigate();
  const {auth, setAuth} = useContext(AuthContext)

  const handleInstructions = () => {
    api.viewedInstructions().then((res) => {
      setAuth({...auth, Instructions: false})
      navigate('education')
    }) 
  }
  
  return (
    <div className="module">
      <div className="module--title">
        <TitleIcon className="titleIcon"/>
        <h4>Instructions</h4>
      </div>
      <Card>
        <div className="instructions">
          <h4>My Portfolio</h4>

          <p><b>Please be sure to read the entire page before starting.</b></p>

          <p>Hi there! Welcome to My Portfolio. Here you can create a personal resume, showcase your work, show off your skills and then, share your portfolio with employers or potential clients.</p> 

          <p>What is cool here is you can enter all of the data you wish to share with each one, and then build a portfolio according to who your target is. If you are targeting potential employers, then the more logical thing is to share your education and work history. If you are sharing with a client, you may want to hold that information back, and only share your skills and existing projects.</p> 
          
          <div>        
            <h4>How to start</h4>     
            <ol>
              <li>Enter your education.</li>
              <li>Enter your work history.</li>
              <li>Enter your skills.</li>
              <li>Enter your projects.</li>
              <li>Create a new portfolio.</li>
              <li>Save a picture</li>
              <li>Click Edit and enter you data.</li>
            </ol>

            <p>This is where you can customize your portfolio according to your intended viewer. If you don't select any employment or education history, the resume tab will not render, same for skills and projects. Select your data and click save.</p>

            <p>You can preview your portfolio by clicking the "view" button. This gives you the opportunity to review and make changes.</p>

            <p>Once you are done reviewing and ready to share, click the "Publish" button. Your portfolio can now be viewed by anyone with the appropriate link. If you change your mind, you can unpublish and your portfolio will be hidden from the public.</p>

            <h4>Account</h4>
            <p>To make changes to your account, such as changing email, password, name or even deleting your account with all of your data, click the button on the bottom of the left navigation containing your initials and select "My Account". Here you can make those changes. Warning, deleting your account will delete all of your data and it is not recoverable.</p>
            
            <h4>Support</h4>
            <p>For support, questions, suggestions, email me at <a href="mailto:info@dewebsites.tech">info@dewebsites.tech</a></p>
          </div>
        </div>

        {auth.Instructions && <div className="btnGroup btnGroup-flex center">
          <button className="btn" onClick={handleInstructions}>Don't show again</button>
        </div>}

      </Card>
    </div>
  )  
}

export default Instructions;