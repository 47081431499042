import api from "context/ApiInstance";

class Employement {  
  getUserEmployement() {
    return api.get('/api/v1/employment')
  }
  newUserEmployement(employment) {
    return api.post(`/api/v1/employment`, employment)
  }
  updateEmployement(id, employment) {
    return api.patch(`/api/v1/employment/${id}`, employment)
  }
  deleteEmployement(id) {
    return api.delete(`/api/v1/employment/${id}`)
  }
}


export default new Employement();

