import api from "context/ApiInstance";

class Auth {
  login (email, password) {   
    return api.post('/api/v1/auth/login', JSON.stringify({email, password}))    
  }
  checkLogin() {
    return api.get('/api/v1/auth/checkLogin');    
  }  
  register(name, email, password, passwordConfirm) {
    return api.post('/api/v1/auth/register', JSON.stringify({name, email, password, passwordConfirm}))
  }
  updateMe(id, data) {
    return api.patch(`/api/v1/auth/updateme/${id}`, data)
  }
  logout() {
    return api.post('/api/v1/auth/logout');
  }
  changePassword(data) {
    return api.patch('/api/v1/auth/updatepassword', data)
  }
  fogotPassword(data) {
    return api.post('/api/v1/auth/forgotPassword', {email: data})
  }
  passwordReset(token, data) {
    return api.patch(`/api/v1/auth/resetPassword/${token}`, data)
  }
}


export default new Auth();

