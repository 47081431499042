import api from "context/ApiInstance";

class Skills {
    getMySkills() {
      return api.get('/api/v1/skills')
    }
    saveNewSkill(formData) {
      return api.post('/api/v1/skills', formData)
    }
    updateSkill(id, formData) {
      return api.patch(`/api/v1/skills/${id}`, formData)
    }
    deleteSkill(id) {
      return api.delete(`/api/v1/skills/${id}`)
    }
}

export default new Skills();