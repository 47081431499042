import React from "react";

import './Modal.scss';


const Modal = (props) => {
    const {classes, key} = props
    
    return (
        <div key={key} className={`modal ${classes ? classes : ''}`}>
            <div className="modal-content">
                           
                { props.children }
           
            </div>
        </div>
    )
}

export default Modal