import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

import { TypeAnimation } from "react-type-animation";

import "aos/dist/aos.css";

const Hero = (props) => {
  const {portfolio} = props;  

  useEffect(() => {
    Aos.init()    
  },[])

  

  return (
    <section id="hero" className="d-flex flex-column justify-content-center align-items-start">
    <div className="hero-container" data-aos="fade-in" data-aos-duration="1000">
      <h1>{portfolio.name}</h1>
      <TypeAnimation sequence={[portfolio.title, 2000]} wrapper="h2"  speed={50} />
      
    </div>
  </section>

  )

}

export default Hero