import React, {useContext, useEffect} from "react";
import AppContext from "../../context/Appcontext";

import './AppmsgModule.scss'


const AppMsgModule = () => {
    const { appMsg, setAppMsg } = useContext(AppContext);  

    useEffect(() => {
        const timer = setTimeout(function() {
                setAppMsg({
                    state: false,
                    class: '',
                    msg:  ''
                })
            }, 2500)
        return () => clearTimeout(timer)
      }, [appMsg.state, setAppMsg])
    

    if (appMsg.state) {
        return (
            <div className={`app-msg ${appMsg.class ? appMsg.class : 'checking'}`}>
                <p>{appMsg.msg}</p>
            </div>
        )
    }
}

export default AppMsgModule