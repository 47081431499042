import React, {useState} from "react";
import {ReactComponent as Chevron} from  'icons/fa-chevron-right.svg';
import InputField from "components/Forms/Input";

const LinksInfo = ({props}) => {
  const {onChange, formState} = props
  const [open, setOpen] = useState(true)
  const links = ['linkedin', 'github', 'youtube', 'twitter', 'facebook', 'instagram', 'mywebsite']  

  return (
    <section className={`edit-portfolio ${open ? 'open' : ''}`}>
        <h3 onClick={()=>setOpen(!open)}><Chevron className={open ? 'open' : ''}/> Add social media links</h3>
        {<div className="fields container">                
          {links.map((item, index)=> {
            return <>
            <InputField index={`${index}_links`} id={item} label={item} type={'url'} value={formState[item]} placeholder={`https://www.${item}.com`} formData={onChange} />

            </>
          })}          
        </div>}
    </section>
  )
}

export default LinksInfo