import React, {useState, useContext, useEffect} from "react";
import {Link} from 'react-router-dom'

import MobileNavButton from 'components/Navigation/MobileNavButton'
import UserMenu from "./UserMenu";

import MyPorfolioLogo from 'images/logo512.png';
import AuthContext from "context/AuthProvider";

import NavigationElement from "layout/SideNavigation/NavigationLinks/NavigationLinks";

import ScrollNavigation from "layout/SideNavigation/NavigationLinks/ScrollNavigation";
import MyPortfolioLinks from "layout/SideNavigation/NavigationLinks/MyPortfolioLinksConfig";

import 'components/Navigation/Navigation.scss'

const Navigation = (props) => {
  const {portfolio, portfolioLinks, scrollNavigation, page} = props;
  const {auth} = useContext(AuthContext)
  const [showNav, setShowNav] = useState(false)
  const [loadClass, setLoadClass] = useState(true)
  const [windowWidth, setWindowWidth] = useState(false)
  const [userMenu, setUserMenu] = useState(false)
  const [loadedChilds, setLoadedChilds] = useState(false)
  const [currentPage, setCurrentPage] = useState(page)

  const getUserInitials = () => {
    let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');
    let initials = auth.name ? [...auth.name.matchAll(rgx)] : false;
    if (initials) initials = (
      (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
    ).toUpperCase()    
    if (initials) {
      return <button className="btn btn-user" title='Account' onClick={handleUserMenu}>{initials}</button>         
    }
    }

  const handleUserMenu = () => {
    setUserMenu(!userMenu)
    if (!showNav) {
      showHideNavigation(!showNav)
    }
  }

  const showHideNavigation = () => {  
    setLoadClass(false)    
    setShowNav(!showNav)  
    setTimeout(() => {
    }, 500)
  }

  const handleWindowWidth = () => {
    setWindowWidth(window.innerWidth)
    windowWidth <= 760 ? setShowNav(false) : setShowNav(true)                
    windowWidth <= 760 ? setLoadClass(true) : setLoadClass(false)    
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowWidth);    
    window.addEventListener('load', handleWindowWidth);    
    // Prevent infinite loop
    return () => {
       window.removeEventListener('resize', handleWindowWidth);      
       window.removeEventListener('load', handleWindowWidth);      
    }    
  }, [])
  
  return (
    <div className="mega-nav">    
      <div className="mega-nav--wrapper">
        <div className="mega-nav--left">
          <div className="top">
            <MobileNavButton handleNav={showHideNavigation} active={showNav}/>
          </div>          
            {portfolio && portfolioLinks}          
          <div className="bottom">
            {getUserInitials()}
          </div>
        </div>
        <div className={`mega-nav--right ${loadClass ? 'mobile' : ''} ${showNav ? 'show' : 'hide'}`}>          
            <div className="mega-nav--header">
              {!portfolio &&
                <img src={MyPorfolioLogo} alt='My Portfolio'/>
              }
              {portfolio && <>
                <img src={`/img/portfolio/${portfolio.portfolioPhoto}`} onError={(e)=>e.currentTarget.src = '/img/portfolio/default.jpg'} alt='My Portfolio' />
                <h2>{portfolio.name}</h2>
                <Link style={{fontSize: '1.2rem'}} to={`mailto:${portfolio.email}`}><i>{portfolio.email}</i></Link>                
              </>}
            </div>     

            {currentPage === 'setup' && <NavigationElement navhandler={showHideNavigation}/>}            
            {currentPage === 'myportfolio' && 
            <ScrollNavigation portfolio={portfolio} navhandler={showHideNavigation} />                   }
            
            {auth && userMenu && <UserMenu navhandler={showHideNavigation} handleUserMenu={handleUserMenu} />}

          </div>
        </div>    
      </div>
    
    )
  }


export default Navigation