import React from 'react'
import { Link } from 'react-router-dom'
import SharePortfolio from 'pages/addPortfolios/SharePortfolio'

const Footer = (props) => {
  const {portfolio} = props
  return (
    <footer className='footer'>
      <div className="footer-wrapper">

      {portfolio && <div className="col-lg-12" style={{display: 'flex', justifyContent:'center', flexDirection: 'column', alignItems: 'center'}}>
            <h4>Share</h4>
            <SharePortfolio portfolio={portfolio}/>
          </div>}


        <div className="row">          
          <div className="col-lg-6">
            <div>
              <h4>My Portfolio</h4>            
              <br/>
              <ul>                
                <li><Link to={'/login'}>Login</Link></li>
                <li><Link to={'/login'}>Create a Portfolio </Link></li>                
                <br/>
                <li><Link to={'mailto:info@dewebsites.tech'}>Support: info@dewebsites.tech</Link> </li>
                <li><Link to={'mailto:info@dewebsites.tech'}>Suggestions: info@dewebsites.tech</Link> </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="img">
              <img src='/img/portfolio/logo.png' alt="My Portfolio"/>            
            </div>
          </div>

        </div>


        <div className="row justify-content-md-center">
          <div className="img">
            <p style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>&#169;
              <Link to={'https://dewebsites.tech'} target='_blank'>
                <img src='/img/dewebsites/deweb-logo-white-54x384.png' alt="DeWebsites"/>
              </Link>{new Date().getFullYear()}</p>
          </div>

        </div>

      </div>
      
    </footer>
  )
}

export default Footer